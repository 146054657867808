import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material'
import { User } from '../../modules/users/User.Service'
import { MAIN } from '../../values/Colors'
import CustomButton from '../../components/CustomButton'
import { Quiz, SupervisorAccount } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export default function SettingsScreen() {
    const [user, setUser] = useState<any>(null)
    const [isLoading, setLoading] = useState<any>(true)
    const navigate = useNavigate()

    async function getCurrentUser() {
        const currentUser = await User.getCurrentUser()
        setUser(currentUser)
        setLoading(false)
    }

    async function logout() {
        await User.logout()
        navigate('/')
    }


    const AuthorizationRoleComponent = () => {
        return (
            <>
            {
                user.attributes.roles.map((item: string, index: number) => {
                    if (item === 'admin') {
                        return (
                            <Box key={index} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
                                <SupervisorAccount />
                                <Typography>{item}</Typography>
                            </Box>
                        )
                    }
                    if (item === 'tester') {
                        return (
                            <Box key={index} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
                                <Quiz />
                                <Typography>{item}</Typography>
                            </Box>
                        )
                    }
                    return <Typography key={index}>{item}</Typography>
                })
            }
            </>
        )
    }

    useEffect(() => {
        getCurrentUser()
    }, [])

    return (
        <Box>
            {
                !isLoading
                    ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '10px' }}>
                            <img src={user.attributes.photo} style={{ width: '150px', height: '150px', borderRadius: '150px' }}></img>
                            <Box sx={{paddingTop: '10px'}}>
                                <Typography variant='h4'>Welcome, {user.attributes.firstName}</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Card sx={{padding: '20px', marginTop: '10px'}}>
                                <Typography sx={{fontWeight: 'bold'}}>User Email</Typography>
                                <Typography>{user.attributes.email}</Typography>
                            </Card>
                            <Card sx={{padding: '20px', marginTop: '10px'}}>
                                <Typography sx={{fontWeight: 'bold'}}>Authorization Roles</Typography>
                                {
                                    AuthorizationRoleComponent()
                                }
                            </Card>
                            <Box sx={{paddingTop: '10px'}}>
                                <CustomButton onClick={logout}>Logout</CustomButton>
                            </Box>
                        </Box>
                    </>
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress sx={{ color: MAIN }} />
                    </Box>

            }


        </Box>
    )
}