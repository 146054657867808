import { Box, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { IDynamicFormTextboxSelect } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormTextboxSelect";
import SelectInput from "../SelectInput";
import { useState } from "react";

export default function DynamicFormTextboxSelect(props: {
  content: IDynamicFormTextboxSelect;
}) {
  const [selectedValue, setSelectedValue] = useState<string>('')


  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography variant="subtitle1">{props.content.placeholder}</Typography>
        {props.content.isRequired && (
          <Typography sx={{ color: "red", fontWeight: "bold" }}>*</Typography>
        )}
        {props.content.tipMessage && (
          <Tooltip title={props.content.tipMessage}>
            <Info sx={{ width: "20px", color: "gray" }}></Info>
          </Tooltip>
        )}
      </Box>
      <Box>
        <SelectInput
          sx={{
            padding: "5px 10px",
            color: "darkgray",
            width: "100%",
            border: "none",
            "& fieldset": { border: "none" },
            borderRadius: "5px",
            backgroundColor: "whitesmoke",
          }}
          value={selectedValue}
          options={props.content.array}
          onChange={(evt) => {
            const targetValue = evt.target.value;
            if (typeof targetValue === "string") {
              setSelectedValue(targetValue);
            }
        }}
        ></SelectInput>
      </Box>
    </Box>
  );
}
