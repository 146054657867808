import { Box, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { IDynamicFormTitle } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormTitle";

export default function DynamicFormTitle(props: {
  content: IDynamicFormTitle;
}) {
  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography sx={{ fontWeight: "bold" }} variant="body1">
          {props.content.value}
        </Typography>
        {props.content.tipMessage && (
          <Tooltip title={props.content.tipMessage}>
            <Info sx={{ width: "20px", color: "gray" }}></Info>
          </Tooltip>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography sx={{ fontWeight: "thin" }} variant="subtitle1">
          {props.content.subtitle}
        </Typography>
      </Box>
    </Box>
  );
}
