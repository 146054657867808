import React, { useEffect, useState } from "react"
import { Box, Card, TextField, Button, Typography, CircularProgress } from "@mui/material"
import { ProgressButton } from "../components/ProgressButton"
import { MAIN } from "../values/Colors"
import { User } from "../modules/users/User.Service"
import { useNavigate } from "react-router-dom"


export default function ConfirmEmailAuthorizationScreen() {
    const [error, setError] = useState("")
    const [authCode, setAuthCode] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function sendEmailAuthorization() {
        try {
            await User.sendEmailAuthorization()
        } catch(error: any) {
            if (error?.name === 'LOCATION_ALREADY_AUTHORIZED')
                return navigate("/confirm-pin")
            if (error?.name === 'TOKEN_UNAVAILABLE')
                localStorage.removeItem('authenticationToken')
                alert('Session Expired!')
                return navigate("/")
        }                               
    }

    async function confirmEmailAuthorization() {
        setLoading(true)

        try {
            const confirmation = await User.confirmEmailAuthorization(authCode)
            navigate("/confirm-pin")
            setLoading(false)
        } catch(error: any) {
            setLoading(false)
            if (error?.name === 'AUTH_CODE_NOT_MATCH')
                return setError(`Code not match, you have ${error.availableAttempts} available attempts`)
            if (error?.name === 'MAX_CODE_ATTEMPT_REACHED')
                return setError(`Maximum attempt reached, try again later`)
            return setError("Incorrect entry")
        }
    }

    useEffect(() => {
        sendEmailAuthorization()
    }, [])

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", background: MAIN}}>
            <Card sx={{padding: "50px", borderRadius: 5}}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "40vw", maxWidth: "500px", flexDirection: "column", gap: 2}}>
                    <img src={require("../assets/images/bilis_logo.png")} style={{width: "200px", marginBottom: "30px"}}></img>
                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: 2}}>
                        <Typography>We've sent a confirmation code to your email.</Typography>
                        {
                            !error 
                                ? <TextField 
                                    value={authCode} 
                                    label="Enter Code" 
                                    inputProps={{ maxLength: 6 }}
                                    onChange={(evt) => setAuthCode(evt.target.value)}
                                    >
                                    </TextField>
                                : <TextField 
                                    value={authCode} 
                                    error 
                                    label="Enter Code" 
                                    helperText={error}
                                    inputProps={{ maxLength: 6 }}
                                    onChange={(evt) => setAuthCode(evt.target.value)}>
                                    </TextField>
                        }
                        <ProgressButton label={"Confirm"} isLoading={loading} onClick={confirmEmailAuthorization}></ProgressButton>
    
                    </Box>
                </Box>
            </Card>
        </Box>
    )
}