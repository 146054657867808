import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import { GridRowsProp, GridValueGetterParams } from '@mui/x-data-grid'
import { Application } from '../../modules/applications/Application.Service'
import { MAIN } from '../../values/Colors'
import BasicModal from '../../components/Modal'
import { Delete } from '@mui/icons-material'
import CustomEditableDataGrid from '../../components/CustomEditableDataGrid'
import { randomId, randomTraderName, randomArrayItem, randomCreatedDate } from '@mui/x-data-grid-generator'
import { useNavigate } from 'react-router-dom'
import { User } from '../../modules/users/User.Service'

const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
    return randomArrayItem(roles);
};

const initialRows: any = [
    {
        id: randomId(),
        name: randomTraderName(),
        age: 25,
        joinDate: randomCreatedDate(),
        role: randomRole(),
    },
    {
        id: randomId(),
        name: randomTraderName(),
        age: 36,
        joinDate: randomCreatedDate(),
        role: randomRole(),
    },
    {
        id: randomId(),
        name: randomTraderName(),
        age: 19,
        joinDate: randomCreatedDate(),
        role: randomRole(),
    },
    {
        id: randomId(),
        name: randomTraderName(),
        age: 28,
        joinDate: randomCreatedDate(),
        role: randomRole(),
    },
    {
        id: randomId(),
        name: randomTraderName(),
        age: 23,
        joinDate: randomCreatedDate(),
        role: randomRole(),
    },
];
export default function ApplicationScreen() {
    const [isLoading, setLoading] = useState(true)
    const [deleteConfirmationModalVisible, setDeleteConfirmationModalVisible] = useState(false)
    const [deleteConfirmationModalLoading, setDeleteConfirmationModalLoading] = useState(false)
    const [itemPicked, setItemPicked] = useState<any>()
    const [applications, setApplications] = useState<any>()

    async function getApplications() {
        let versions: Array<{ id: any, os: string, version: string }> = []
        const applicationAndroidVersions: any = await Application.getApplication('android')
        const applicationIOSVersions: any = await Application.getApplication('ios')

        applicationAndroidVersions.attributes.version.forEach((item: any, index: number) => {
            versions.push({
                id: item,
                os: 'android',
                version: item
            })
        })

        applicationIOSVersions.attributes.version.forEach((item: any, index: number) => {
            versions.push({
                id: item,
                os: 'ios',
                version: item
            })
        })
        setApplications(versions)
        setLoading(false)
    }



    useEffect(() => {
        getApplications()
    }, [isLoading])


    const ModalComponents = () => {
        return (
            deleteConfirmationModalVisible &&
            <BasicModal
                isLoading={deleteConfirmationModalLoading}
                title='Confirm Delete?'
                description={`Are you sure you want to delete item with os '${itemPicked?.os}' version '${itemPicked?.version}'`}
                isOpen={deleteConfirmationModalVisible}
                handleClose={() => setDeleteConfirmationModalVisible(false)}
                handleConfirm={async () => {
                    setDeleteConfirmationModalLoading(true)
                    if (itemPicked.os === 'android') {
                        await Application.removeApplicationAvailableVersion('android', itemPicked.version)
                    }
                    if (itemPicked.os === 'ios') {
                        await Application.removeApplicationAvailableVersion('ios', itemPicked.version)
                    }
                    setDeleteConfirmationModalVisible(false)
                    setDeleteConfirmationModalLoading(false)
                    getApplications()
                }} />
        )
    }

    return (
        <Box>
            {
                ModalComponents()
            }
            {
                !isLoading
                    ?
                    <CustomEditableDataGrid

                        rows={applications}
                        columns={[
                            {
                                field: 'os',
                                headerName: 'OS',
                                type: 'singleSelect',
                                editable: true,
                                width: 180,
                                valueOptions: ['android', 'ios']
                            },
                            {
                                field: 'version',
                                headerName: 'Version',
                                width: 180,
                                editable: true
                            },
                        ]}
                        onPaginationModelChanged={() => {}}
                        onItemDelete={async (item) => {
                            setItemPicked(item)
                            setDeleteConfirmationModalVisible(true)
                        }}
                        onItemAdded={async (item, closeModal) => {
                            setItemPicked(item)
                            if (item.os === 'android') {
                                await Application.addApplicationAvailableVersion('android', item.version)
                            }
                            if (item.os === 'ios') {
                                await Application.addApplicationAvailableVersion('ios', item.version)
                            }

                            closeModal()
                            setApplications([...applications, {
                                id: item.version,
                                os: item.os,
                                version: item.version
                            }])
                        }}
                        fields={[
                            {
                                name: "os",
                                label: "OS",
                                type: "picker",
                            },
                            {
                                name: "version",
                                label: "Version",
                                type: "picker",
                            }
                        ]}
                        AllowEditAction={true}
                    ></CustomEditableDataGrid>
                    :
                    <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", height: "30vw" }}>
                        <CircularProgress sx={{ color: MAIN }} />
                    </Box>
            }
        </Box>
    )
}