import axios from "axios"
import { User } from "../users/User.Service"
import { Server } from "../../values/Server"


class _BarangayRegistration {
    async getAllBarangayRegistrations(page: number, limit: number, additionalQuery: string = '') {
        return new Promise(async (resolve, reject) => {
            const accessToken = await User.getAccessToken()
            axios({
                url: `${Server.API_ENDPOINT}/barangay-registrations?pagination[page]=${page}&pagination[limit]=${limit}${additionalQuery}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }).then((snapshot) => {
                return resolve(snapshot.data)
            }).catch(async (error) => {
                if (error?.response?.data.error.name === 'TokenExpiredError') {
                    await User.refreshUser()
                    const data = await this.getAllBarangayRegistrations(page, limit, additionalQuery)
                    return resolve(data)
                }
                return reject(error)
            })
        })
    }

    async declineBarangayRegistration(id: string) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await User.getAccessToken()
            axios({
                url: `${Server.API_ENDPOINT}/barangay-registration/${id}/decline`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }).then((snapshot) => {
                return resolve(snapshot.data)
            }).catch(async (error) => {
                if (error?.response?.data.error.name === 'TokenExpiredError') {
                    await User.refreshUser()
                    const data = await this.declineBarangayRegistration(id)
                    return resolve(data)
                }
                return reject(error)
            })
        })
    }

}


export const BarangayRegistration = new _BarangayRegistration()