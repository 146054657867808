import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginScreen from './screens/LoginScreen';
import PinScreen from './screens/PinScreen';
import DashboardScreen from './screens/DashboardScreen';
import { ThemeProvider, createTheme } from '@mui/material';
import './index.css';
import ConfirmEmailAuthorizationScreen from './screens/ConfirmEmailAuthorizationScreen';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginScreen />}></Route>
          <Route path="/confirm-pin" element={<PinScreen />}></Route>
          <Route path="/confirm-email-authorization" element={<ConfirmEmailAuthorizationScreen />}></Route>
          <Route path="/dashboard/*" element={<DashboardScreen />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
export default App;
