import axios from "axios"
import { Server } from "../../values/Server"

const resourcePath = "user"

class _User {
    async getAccessToken() {
        return localStorage.getItem('accessToken')
    }
    async setAccessToken(accessToken: string) {
        return localStorage.setItem('accessToken', accessToken)
    }
    async getAuthenticationToken() {
        return localStorage.getItem('authenticationToken')
    }
    async setAuthenticationToken(authenticationToken: string) {
        return localStorage.setItem('authenticationToken', authenticationToken)
    }
    async getCurrentUser() {
        return new Promise(async (resolve, reject) => {
            const accessToken = await this.getAccessToken()
            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/me`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }).then((snapshot) => {
                return resolve(snapshot.data.data)
            }).catch(async (error) => {

                if (error?.response?.data.error.name === 'TokenExpiredError') {
                    await this.refreshUser()
                    const user = await this.getCurrentUser()
                    return resolve(user)
                }
                return reject(error)
            })
        })
    }
    async refreshUser() {
        return new Promise(async (resolve, reject) => {
            const accessToken = await this.getAccessToken()
            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/me/refresh`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }).then(async (snapshot) => {
                const accessToken = snapshot.data.data.attributes.accessToken
                await this.setAccessToken(accessToken)
                return resolve(snapshot.data.data)
            }).catch((error) => {
                if (error.response.data.error.name === 'ACCESS_TOKEN_INVALID') {
                    return reject(error)
                }
                return reject(error)
            })
        })
    }
    async checkTokenValidity(navigateTo: any) {
        return new Promise(async (resolve, reject) => {
            try {
                await this.getCurrentUser()
                resolve(null)
            } catch(error: any) {
                if (error.response.data.error.name === 'ACCESS_TOKEN_INVALID') {
                    return navigateTo('/confirm-pin')
                }
            } 
        })
    }
    async loginUser(identifier: string, password: string) {
        return new Promise((resolve, reject) => {
            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/login`,
                method: 'POST',
                data: {
                    data: {
                        identifier: identifier,
                        password: password
                    }
                }
            }).then(async (snapshot) => {
                const authenticationToken = snapshot.data.data.attributes.authenticationToken
                await this.setAuthenticationToken(authenticationToken)
                return resolve(snapshot.data.data)
            }).catch((error) => {
                if (error.response.data.error) return reject(error.response.data.error)
                return reject(error.response)
            })
        })
    }
    async sendEmailAuthorization() {
        return new Promise(async (resolve, reject) => {
            const authenticationToken = await this.getAuthenticationToken()
            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/me/send-email-authorization`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authenticationToken}`
                }
            }).then((snapshot) => {
                return resolve(snapshot.data.data)
            }).catch((error) => {
                console.log(error)
                if (error.response?.data.error) return reject(error.response.data.error)
                return reject(error)
            })
        })
    }
    async confirmEmailAuthorization(authCode: string) {
        return new Promise(async (resolve, reject) => {
            const authenticationToken = await this.getAuthenticationToken()
            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/me/security-log/email/authorize`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authenticationToken}`
                },
                data: {
                    data: {
                        authCode: authCode
                    }
                }
            }).then((snapshot) => {
                return resolve(snapshot.data.data)
            }).catch((error) => {
                if (error.response.data.error) return reject(error.response.data.error)
                return reject(error.response)
            })
        })
    }
    async confirmPin(pin: string) {
        return new Promise(async (resolve, reject) => {
            const authenticationToken = await this.getAuthenticationToken()
            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/me/pin/confirm`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authenticationToken}`
                },
                data: {
                    data: {
                        currentPin: pin
                    }
                }
            }).then(async (snapshot) => {
                const accessToken = snapshot.data.data.attributes.accessToken
                await this.setAccessToken(accessToken)
                return resolve(snapshot.data.data)
            }).catch((error) => {
                if (error.response.data.error) return reject(error.response.data.error)
                return reject(error.response)
            })
        })
    }
    async getAllUsers(page: number, limit: number, additionalQuery: string) {
        await this.getCurrentUser()
        const accessToken = await this.getAccessToken()

        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/users?pagination[page]=${page}&pagination[limit]=${limit}&sort[createdAt]=-1${additionalQuery}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(async error => {
                reject(error)
            })
        })
    }

    async getUserByEmail(email: string = '') {
        await this.getCurrentUser()
        const accessToken = await this.getAccessToken()

        return new Promise(async (resolve, reject) => {
            await axios({
                method: 'GET',
                url: `${Server.API_ENDPOINT}/user/by-identifier/${email}`,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(snapshot => {
                resolve(snapshot.data)
            }).catch(async error => {
                reject(error)
            })
        })
    }
    // async getUserById(id: string) {
    //     return new Promise(async (resolve, reject) => {
    //         const authenticationToken = await this.getAccessToken()
    //         axios({
    //             url: `${Server.API_ENDPOINT}/${resourcePath}/${id}`,
    //             method: 'PATCH',
    //             headers: {
    //                 Authorization: `Bearer ${authenticationToken}`
    //             },
    //             data: {
    //                 data: {
    //                     roles: authCode
    //                 }
    //             }
    //         }).then((snapshot) => {
    //             return resolve(snapshot.data.data)
    //         }).catch((error) => {
    //             if (error.response.data.error) return reject(error.response.data.error)
    //             return reject(error.response)
    //         })
    //     })
    // }
    logout() {
        localStorage.removeItem('authenticationToken')
        localStorage.removeItem('accessToken')
    }
    async addAsTester(id: string) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await this.getAccessToken()
            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/${id}`,
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    data: {
                        roles: ['tester']
                    },
                    meta: {
                        array: {
                            operation: "add"
                        }
                    }
                }
            }).then((snapshot) => {
                return resolve(snapshot.data.data)
            }).catch((error) => {
                if (error.response.data.error) return reject(error.response.data.error)
                return reject(error.response)
            })
        })
    }
    async removeAsTester(id: string) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await this.getAccessToken()
            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/${id}`,
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    data: {
                        roles: ['tester']
                    },
                    meta: {
                        array: {
                            operation: "remove"
                        }
                    }
                }
            }).then((snapshot) => {
                return resolve(snapshot.data.data)
            }).catch((error) => {
                if (error.response.data.error) return reject(error.response.data.error)
                return reject(error.response)
            })
        })
    }
}

export const User = new _User()