import { Box, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { IDynamicFormCalendar } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormCalendar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function DynamicFormCalendar(props: {
  content: IDynamicFormCalendar;
}) {
  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography variant="subtitle1">{props.content.placeholder}</Typography>
        {props.content.isRequired && (
          <Typography sx={{ color: "red", fontWeight: "bold" }}>*</Typography>
        )}
        {props.content.tipMessage && (
          <Tooltip title={props.content.tipMessage}>
            <Info sx={{ width: "20px", color: "gray" }}></Info>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          marginBottom: "10px",
          backgroundColor: "whitesmoke",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            defaultValue={props.content.minimumDate}
            label={props.content.placeholderValue || ""}
            sx={{
              color: "darkgray",
              width: "100%",
              border: "none",
              margin: 0,
              "& fieldset": { border: "none" },
              backgroundColor: "whitesmoke",
            }}
            onChange={(date) => {
              //const getDate = dayjs(date).toISOString();
              //handleOnChange("minimumDate", getDate);
            }}
          ></DatePicker>
        </LocalizationProvider>
      </Box>
    </Box>
  );
}
