import { TextField, TypographyProps } from "@mui/material";
import { toCamelCase } from "../utils/TextFormat";
import { useEffect, useState } from "react";

export default function DynamicFormField(props: {
    label: string, 
    value: string, 
    handleOnChange: (key: string, value: string) => void
    typographyProps?: any
}) {
    return (
        <TextField sx={{ width: '100%', marginBottom: '10px' }} {...props.typographyProps } variant="outlined" value={props.value || ''} label={props.label} placeholder={props.label}  onChange={(evt) => {
            props.handleOnChange(toCamelCase(props.label), evt.target.value)
        }}></TextField>
    )
}