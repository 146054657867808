import { Box, Tooltip, Typography, TypographyProps } from "@mui/material";
import { Info } from "@mui/icons-material";
import { IDynamicFormValue } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormValue";
import { faker } from '@faker-js/faker';
import { useState } from "react";

export default function DynamicFormValue(props: {
  content: IDynamicFormValue;
}) {

  const [generateWords, setGenerateWords] = useState<string>(faker.lorem.words())
  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography sx={{ fontWeight: "thin" }} variant="subtitle1">
          {props.content.placeholder}
        </Typography>
        {props.content.tipMessage && (
          <Tooltip title={props.content.tipMessage}>
            <Info sx={{ width: "20px", color: "gray" }}></Info>
          </Tooltip>
        )}
      </Box>
      <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">
          {generateWords.toUpperCase()}
      </Typography>
    </Box>
  );
}
