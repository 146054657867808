import { Image, KeyboardBackspaceSharp } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

export function CustomDropzone(props: { allowedTypes?: string[], onDrop: (params: { file: any, localFileUrl: string }) => void, onError: (error: any) => void }) {

  const [file, setFile] = useState<any>('')
  const [fileLocalUrl, setFileLocalUrl] = useState<any>('')

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {

      if (props.allowedTypes) {
        const isAllowed = props.allowedTypes.find((item) => file.type.includes(item))
        if (!isAllowed) 
          return props.onError({
            name: "FILE_TYPE_NOT_ALLOWED",
            message: `The filetype '${file.type}' is not allowed, only '${props.allowedTypes}' is allowed`
          })
      }

      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr: any = reader.result
        var blob = new Blob([binaryStr], { type: "image/jpeg" });
        const url = window.URL.createObjectURL(blob)
        setFile(file)
        setFileLocalUrl(url)
      }
      reader.readAsArrayBuffer(file)
    })

  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })


  useEffect(() => {
    props.onDrop({
      file: file,
      localFileUrl: fileLocalUrl
    })
  }, [file])

  return (
    <Box {...getRootProps()}>

      <Box sx={{ border: "1px solid gray", padding: "10px", borderStyle: "dashed", borderRadius: "5px", cursor: "pointer", alignItems: "center", alignContent: "center", justifyContent: "center" }}>
        {
          file ?
            <PreviewComponent getInputProps={getInputProps} type={file.type} source={fileLocalUrl} />
            :
            <Box sx={{ display: "flex", height: "100%", flexDirection: "column", alignItems: "center", alignContent: "center", justifyContent: "center" }}>
              <Box>
                <Image></Image>
              </Box>
              <Box>
                <input {...getInputProps()} />
                <Typography>Drag and drop some files here, or click to select files</Typography>
              </Box>
            </Box>
        }
      </Box>
    </Box>
  )
}


const PreviewComponent = (props: { type: "video" | "image", source: string, getInputProps: any }) => {
  if (props.type.includes("image")) {
    return (
      <Box sx={{ display: "flex", height: "100%", flexDirection: "column", alignItems: "center", alignContent: "center", justifyContent: "center" }}>
        <Tooltip title={"Drag and drop some files here, or click to select files"}>
          <Box>
            <input {...props.getInputProps()} />
            <img style={{width: "100%"}} src={props.source}></img>
          </Box>
        </Tooltip>
      </Box>
    )
  }
  return <Box></Box>
}