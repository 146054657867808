import {
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { MAIN, MAIN_DARK } from "../values/Colors";
import { AddCircleOutline } from "@mui/icons-material";
import { produce } from "immer";

export function DataGridToolbar(props: {
  addNewItem?: {
    onCreate: (values: any, isLoading: (isLoading: boolean) => void, closeModal: () => void) => void;
    onCancel?: () => void;
    createItemFields: Array<{
      label: string;
      value: string;
    }>;
  };
}) {
  const [fields, setFields] = useState<object>({});
  const [visibleModals, setVisibleModals] = useState<string[]>([])
  const [loadingModals, setLoadingModals] = useState<string[]>([])

  async function handleOnChange(
    key: string,
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const text = evt.target.value;

    setFields((prevState) => {
      return { ...prevState, [key]: text };
    });
  }

  return (
    <Card sx={{ display: "flex", marginBottom: "10px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {props.addNewItem && (
          <>
            <Button
              sx={{
                color: MAIN,
                textTransform: "none",
                padding: "10px",
                gap: 1,
              }}
              onClick={() => {
                const _visibleModals = produce(visibleModals, (draft: any) => {
                    draft.push('addNewItemModal')
                })

                setVisibleModals(_visibleModals)
              }}
            >
              <AddCircleOutline sx={{ width: "20px" }}></AddCircleOutline>
              <Typography>New Template</Typography>
            </Button>
            <Modal
              open={visibleModals.includes('addNewItemModal')}
              onClose={() => {}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Box sx={{ marginBottom: "10px" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                  >
                    Add New Item
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                    gap: 1,
                  }}
                >
                  {props.addNewItem?.createItemFields &&
                    props.addNewItem.createItemFields.map((item, index) => {
                      return (
                        <TextField
                          key={index}
                          label={item.label}
                          onChange={(evt) => handleOnChange(item.value, evt)}
                        ></TextField>
                      );
                    })}
                </Box>

                {!loadingModals.includes("addNewItemModal") ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "row",
                      width: "100%",
                      gap: 1,
                    }}
                  >
                    <Button
                      sx={{ color: MAIN }}
                      onClick={() => {
                        const _visibleModals = visibleModals.filter((item) => item !== "addNewItemModal")
                        props.addNewItem?.onCancel && props.addNewItem?.onCancel();
                        setVisibleModals(_visibleModals)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: MAIN,
                        "&:hover": { background: MAIN_DARK },
                        color: "white",
                      }}
                      variant="contained"
                      onClick={() => {
                        props.addNewItem?.onCreate(fields, (isLoading) => {
                            if (isLoading) {
                                const _loadingModals = produce(loadingModals, (draft: any) => {
                                    draft.push('addNewItemModal')
                                })
                                setLoadingModals(_loadingModals)
                            }

                            if (!isLoading) {
                                const _loadingModals = loadingModals.filter((item) => item !== "addNewItemModal")
                                setLoadingModals(_loadingModals)
                            }
                        }, () => {
                            const _visibleModals = visibleModals.filter((item) => item !== "addNewItemModal")
                            setVisibleModals(_visibleModals)
                        });
                      }}
                    >
                      Create
                    </Button>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "row",
                      width: "100%",
                      padding: "10px",
                      gap: 1,
                    }}
                  >
                    <CircularProgress sx={{ color: MAIN }}></CircularProgress>
                  </Box>
                )}

                <Box sx={{ padding: 2 }}></Box>
                <Box
                  sx={{ display: "flex", justifyContent: "right", gap: 2 }}
                ></Box>
              </Box>
            </Modal>
          </>
        )}
      </Box>
    </Card>
  );
}
