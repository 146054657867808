import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Pagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormTemplate } from "../../../modules/form_templates/FormTemplate.Service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MAIN } from "../../../values/Colors";
import SelectInput from "../../../components/SelectInput";
import DynamicFormTitle from "../../../components/dynamic_form_views/DynamicFormTitle";
import DynamicFormValue from "../../../components/dynamic_form_views/DynamicFormValue";
import DynamicFormValueField from "../../../components/dynamic_form_fields/DynamicFormValueField";
import { Add, Delete, PostAdd } from "@mui/icons-material";
import { current, produce } from "immer";
import DynamicFormTitleField from "../../../components/dynamic_form_fields/DynamicFormTitleField";
import DynamicFormTextboxField from "../../../components/dynamic_form_fields/DynamicFormTextboxField";
import DynamicFormTextbox from "../../../components/dynamic_form_views/DynamicFormTextbox";
import DynamicFormTextboxSelectField from "../../../components/dynamic_form_fields/DynamicFormTextboxSelectField";
import DynamicFormTextboxSelect from "../../../components/dynamic_form_views/DynamicFormTextboxSelect";
import DynamicFormCalendarField from "../../../components/dynamic_form_fields/DynamicFormCalendarField";
import DynamicFormCalendar from "../../../components/dynamic_form_views/DynamicFormCalendar";
import DynamicFormDividerField from "../../../components/dynamic_form_fields/DynamicFormDividerField";
import DynamicFormDivider from "../../../components/dynamic_form_views/DynamicFormDivider";
import DynamicFormRadioGroupField from "../../../components/dynamic_form_fields/DynamicFormRadioGroupField";
import DynamicFormRadioGroup from "../../../components/dynamic_form_views/DynamicFormRadioGroup";
import DynamicFormNationalityField from "../../../components/dynamic_form_fields/DynamicFormNationalityField";
import DynamicFormNationality from "../../../components/dynamic_form_views/DynamicFormNationality";
import DynamicFormIdentificationDocumentField from "../../../components/dynamic_form_fields/DynamicFormIdentificationDocumentField";
import DynamicFormIdentificationDocument from "../../../components/dynamic_form_views/DynamicFormIdentificationDocument";
import { PageHeader } from "../../../components/PageHeader";
import BasicModal from "../../../components/Modal";
import Save from "@mui/icons-material/Save";
import { ComponentScanForContent } from "../../../utils/ComponentScanForContent";

export default function FormTemplateOverviewScreen() {
  const [formTemplate, setFormTemplate] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [formComponent, setFormComponent] = useState<
    Array<{
      type: string;
      placeholder?: string;
      value?: string;
      tipMessage?: string;
    }>
  >([]);
  const [formView, setFormView] = useState<
    Array<{
      type: string;
      placeholder?: string;
      value?: string;
      tipMessage?: string;
    }>
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [formTemplateComponents, setFormTemplateComponents] = useState<
    Array<any>
  >([[{}]]);
  const [pageLength, setPageLength] = useState<number>(0);
  const [modalSaveChangesVisible, setModalSaveChangesVisible] =
    useState<boolean>(false);
  const [modalSaveChangesLoading, setModalSaveChangesLoading] =
    useState<boolean>(false);
  const [modalSuccessSavingVisible, setModalSuccessSavingVisible] =
    useState<boolean>(false);

  const navigate = useNavigate();

  async function getFormTemplate() {
    const formTemplateId = searchParams.get("id");

    if (formTemplateId) {
      const _getFormTemplate: any = await FormTemplate.getFormTemplate(
        formTemplateId
      );
      setFormTemplate(_getFormTemplate);

      const templateComponents = _getFormTemplate.data.attributes.components;

      setFormTemplateComponents(templateComponents);
      setFormComponent(templateComponents[0]);
      setFormView(templateComponents[0]);
      setPageLength(templateComponents.length);
      setLoading(false);
    }
  }

  async function addComponent() {
    setFormComponent((prevState) => {
      return [
        ...prevState,
        {
          type: "title",
        },
      ];
    });
    setFormView((prevState) => {
      return [
        ...prevState,
        {
          type: "title",
        },
      ];
    });

    const _formTemplateComponents = produce(formTemplateComponents, (draft: any) => {
      draft[currentPage - 1] = formComponent
    })

    setFormTemplateComponents(_formTemplateComponents)
  }

  async function removeComponent(index: number) {
    setFormComponent((prevState) => {
      const _formComponent = [...prevState];
      _formComponent.splice(index, 1);
      return [..._formComponent];
    });
    setFormView((prevState) => {
      const _formView = [...prevState];
      _formView.splice(index, 1);
      return [..._formView];
    });

  }
  
  async function onSave() {
    setModalSaveChangesVisible(true);
  }

  async function onAddPage() {
    setFormTemplateComponents([
      ...formTemplateComponents,
      [
        {
          type: "title",
        },
      ],
    ]);
    setCurrentPage(currentPage + 1);
    setPageLength(pageLength + 1);
  }

  async function onRemoveCurrentPage() {
    const pagesLeft = formTemplateComponents.filter(
      (item, index) => index !== currentPage - 1
    );

    // If current page is at the end of the items, go back one page
    if (currentPage === formTemplateComponents.length) {
      setCurrentPage(currentPage - 1);
    }

    // If current page is not the end of the items, retain
    if (currentPage !== formTemplateComponents.length) {
      setCurrentPage(currentPage);
    }
    setFormTemplateComponents(pagesLeft);
    refreshInterfaceByPage(currentPage);
    setPageLength((prevState) => {
      return (prevState -= 1);
    });
  }

  async function refreshInterfaceByPage(page: number) {
    let currentComponentPage = formTemplateComponents[page];
    setFormComponent(currentComponentPage);
    setFormView(currentComponentPage);
  }

  useEffect(() => {
    setCurrentPage(1);
    getFormTemplate();
  }, []);

  useEffect(() => {
    if (formTemplateComponents?.length > 0) {
      let currentComponentPage = formTemplateComponents[currentPage - 1];
      setFormComponent(currentComponentPage);
      setFormView(currentComponentPage);
    }
  }, [currentPage]);


  useEffect(() => {
    if (formTemplateComponents?.length > 0) {
      const _formTemplateComponents = produce(formTemplateComponents, (draft: any) => {
        draft[currentPage - 1] = formComponent
      })
      setFormTemplateComponents(_formTemplateComponents)
    } 
  }, [formComponent])

  return (
    <Box>
      <BasicModal
        title="Are you sure you wanna save changes?"
        description="Save components for this Form Template"
        handleConfirm={async () => {
          setModalSaveChangesLoading(true);
          const currentFormTemplateId = formTemplate.data.id;
          const defaultContents = await ComponentScanForContent(
            formTemplateComponents
          );

          //Save template
          await FormTemplate.updateFormTemplate(currentFormTemplateId, {
            components: formTemplateComponents,
            defaultContents: defaultContents,
          });
          setModalSaveChangesLoading(false);
          setModalSaveChangesVisible(false);
          setModalSuccessSavingVisible(true);
        }}
        handleClose={() => {
          setModalSaveChangesVisible(false);
        }}
        isOpen={modalSaveChangesVisible}
        isLoading={modalSaveChangesLoading}
      />

      <BasicModal
        title="Success!"
        description="Form Template Components is saved successfully"
        handleConfirm={() => {
          setModalSuccessSavingVisible(false);
        }}
        isOpen={modalSuccessSavingVisible}
        isLoading={false}
      />

      <PageHeader
        title="Edit the Form Template Components"
        onBackButtonClicked={() => {
          navigate("/dashboard/form-template");
        }}
      />

      <Box
        sx={{
          display: "flex",
          float: "right",
          margin: "20px 10px",
          gap: 1,
        }}
      >
        {formTemplateComponents.length > 1 && (
          <Button
            sx={{
              color: MAIN,
              borderColor: MAIN,
              "&:hover": {
                color: MAIN,
                borderColor: MAIN,
              },
            }}
            variant="outlined"
            onClick={onRemoveCurrentPage}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                padding: "5px",
              }}
            >
              <PostAdd></PostAdd>
              <Typography sx={{ textTransform: "none" }}>
                Remove Current Page
              </Typography>
            </Box>
          </Button>
        )}
        {currentPage === pageLength && (
          <Button
            sx={{
              color: MAIN,
              borderColor: MAIN,
              "&:hover": {
                color: MAIN,
                borderColor: MAIN,
              },
            }}
            variant="outlined"
            onClick={onAddPage}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                padding: "5px",
              }}
            >
              <PostAdd></PostAdd>
              <Typography sx={{ textTransform: "none" }}>Add Page</Typography>
            </Box>
          </Button>
        )}
        <Button
          sx={{
            color: "white",
            backgroundColor: MAIN,
            "&:hover": {
              color: "white",
              backgroundColor: MAIN,
            },
          }}
          variant="contained"
          onClick={onSave}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              padding: "5px",
            }}
          >
            <Save></Save>
            <Typography sx={{ textTransform: "none" }}>
              Deploy Changes
            </Typography>
          </Box>
        </Button>
      </Box>
      {!loading ? (
        <>
          {formTemplate &&
          formTemplate.data.attributes.visibility === "public" ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "20vh",
              }}
            >
              <Typography variant="h6">
                Oops! Form Template must be private first before editing.
              </Typography>
            </Box>
          ) : (
            <>
              <Grid container spacing={2} sx={{padding: '20px'}}>
                <Grid item xs={6}>
                  <Box sx={{padding: '10px'}}>
                    <ControlComponent
                      formComponent={formComponent}
                      currentFormTemplate={formTemplate}
                      pageLength={pageLength}
                      currentPage={currentPage}
                      onSelect={(targetValue, index) => {
                        const _formComponent = produce(
                          formComponent,
                          (draft: any) => {
                            draft[index] = {
                              type: targetValue,
                            };
                            return draft;
                          }
                        );

                        const _formView = produce(formView, (draft: any) => {
                          draft[index] = {
                            type: targetValue,
                          };
                          return draft;
                        });

                        setFormComponent(_formComponent);
                        setFormView(_formView);
                      }}
                      onChangeField={(changeResult, index) => {
                        const _formView = produce(formView, (draft: any) => {
                          draft[index] = changeResult[index];
                          return draft;
                        });
                        setFormComponent(_formView);
                        setFormView(_formView);
                        const _formTemplateComponents: any = produce(
                          formTemplateComponents,
                          (draft: any) => {
                            draft[currentPage - 1] = _formView;
                            return draft;
                          }
                        );
                        setFormTemplateComponents(_formTemplateComponents);
                      }}
                      removeComponent={removeComponent}
                      addComponent={addComponent}
                      onPageChanged={(page) => {
                        console.log("page changed", page);
                        setCurrentPage(page);
                      }}
                    ></ControlComponent>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <ViewComponent
                    formTemplate={formTemplate}
                    formView={formView}
                    pageLength={pageLength}
                    currentPage={currentPage}
                  ></ViewComponent>
                </Grid>
              </Grid>
            </>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "30vw",
          }}
        >
          <CircularProgress sx={{ color: MAIN }} />
        </Box>
      )}
    </Box>
  );
}

async function RenderResult(
  props: {
    index: number;
    currentFormComponent: any;
    formComponent: Array<any>;
    onChangeField: (formComponent: any) => void;
  },
  data: any
) {
  const _formComponent = produce(props.formComponent, (draft: any) => {
    draft[props.index] = {
      type: props.currentFormComponent.type,
      ...data,
    };
    return draft;
  });
  props.onChangeField(_formComponent);
}

const options = [
  {
    value: "formValue",
    label: "Form Value",
    formField: (props: {
      index: number;
      currentFormComponent: any;
      formComponent: Array<any>;
      onChangeField: (formComponent: any) => void;
    }) => {
      return (
        <DynamicFormValueField
          key={props.index}
          content={props.currentFormComponent}
          onChange={(data) => {
            return RenderResult(props, data);
          }}
        />
      );
    },
    formView: (index: number, item: any) => {
      return <DynamicFormValue key={index} content={item} />;
    },
  },
  {
    value: "title",
    label: "Title",
    formField: (props: {
      index: number;
      currentFormComponent: any;
      formComponent: Array<any>;
      onChangeField: (formComponent: any) => void;
    }) => {
      return (
        <DynamicFormTitleField
          key={props.index}
          content={props.currentFormComponent}
          onChange={(data) => {
            return RenderResult(props, data);
          }}
        />
      );
    },
    formView: (index: number, item: any) => {
      return <DynamicFormTitle key={index} content={item} />;
    },
  },
  {
    value: "textbox",
    label: "Textbox",
    formField: (props: {
      index: number;
      currentFormComponent: any;
      formComponent: Array<any>;
      onChangeField: (formComponent: any) => void;
    }) => {
      return (
        <DynamicFormTextboxField
          key={props.index}
          content={props.currentFormComponent}
          onChange={(data) => {
            return RenderResult(props, data);
          }}
        />
      );
    },
    formView: (index: number, item: any) => {
      return <DynamicFormTextbox key={index} content={item} />;
    },
  },
  {
    value: "textboxSelect",
    label: "Textbox Select",
    formField: (props: {
      index: number;
      currentFormComponent: any;
      formComponent: Array<any>;
      onChangeField: (formComponent: any) => void;
    }) => {
      return (
        <DynamicFormTextboxSelectField
          key={props.index}
          content={props.currentFormComponent}
          onChange={(data) => {
            return RenderResult(props, data);
          }}
        />
      );
    },
    formView: (index: number, item: any) => {
      return <DynamicFormTextboxSelect key={index} content={item} />;
    },
  },
  {
    value: "calendar",
    label: "Calendar",
    formField: (props: {
      index: number;
      currentFormComponent: any;
      formComponent: Array<any>;
      onChangeField: (formComponent: any) => void;
    }) => {
      return (
        <DynamicFormCalendarField
          key={props.index}
          content={props.currentFormComponent}
          onChange={(data) => {
            return RenderResult(props, data);
          }}
        />
      );
    },
    formView: (index: number, item: any) => {
      return <DynamicFormCalendar key={index} content={item} />;
    },
  },
  {
    value: "divider",
    label: "Divider",
    formField: (props: {
      index: number;
      currentFormComponent: any;
      formComponent: Array<any>;
      onChangeField: (formComponent: any) => void;
    }) => {
      return (
        <DynamicFormDividerField
          key={props.index}
          content={props.currentFormComponent}
          onChange={(data) => {
            return RenderResult(props, data);
          }}
        />
      );
    },
    formView: (index: number, item: any) => {
      return <DynamicFormDivider key={index} content={item} />;
    },
  },
  {
    value: "radioGroup",
    label: "Radio Group",
    formField: (props: {
      index: number;
      currentFormComponent: any;
      formComponent: Array<any>;
      onChangeField: (formComponent: any) => void;
    }) => {
      return (
        <DynamicFormRadioGroupField
          key={props.index}
          content={props.currentFormComponent}
          onChange={(data) => {
            return RenderResult(props, data);
          }}
        />
      );
    },
    formView: (index: number, item: any) => {
      return <DynamicFormRadioGroup key={index} content={item} />;
    },
  },
  {
    value: "nationality",
    label: "Nationality",
    formField: (props: {
      index: number;
      currentFormComponent: any;
      formComponent: Array<any>;
      onChangeField: (formComponent: any) => void;
    }) => {
      return (
        <DynamicFormNationalityField
          key={props.index}
          content={props.currentFormComponent}
          onChange={(data) => {
            return RenderResult(props, data);
          }}
        />
      );
    },
    formView: (index: number, item: any) => {
      return <DynamicFormNationality key={index} content={item} />;
    },
  },
  {
    value: "identificationDocument",
    label: "Identification Document",
    formField: (props: {
      index: number;
      currentFormComponent: any;
      formComponent: Array<any>;
      onChangeField: (formComponent: any) => void;
    }) => {
      return (
        <DynamicFormIdentificationDocumentField
          key={props.index}
          content={props.currentFormComponent}
          onChange={(data) => {
            return RenderResult(props, data);
          }}
        />
      );
    },
    formView: (index: number, item: any) => {
      return <DynamicFormIdentificationDocument key={index} content={item} />;
    },
  },
];

const DynamicFormIdentifier = (props: {
  formComponent: Array<any>;
  currentFormComponent: any;
  index: number;
  onChangeField: (formComponent: any) => void;
}) => {
  const findOption: any = options.find(
    (item) => item.value === props.currentFormComponent.type
  );
  // console.log(props.formComponent)
  // console.log('cfc')
  // console.log(props.currentFormComponent)
  if (findOption?.formField) {
    const formComponent = findOption.formField(props);
    return formComponent;
  }
  return <Box></Box>;
};

const ControlComponent = (props: {
  formComponent: Array<{
    type: string;
    placeholder?: string;
    value?: string;
    tipMessage?: string;
  }>;
  currentFormTemplate: any;
  pageLength: number;
  currentPage: number;
  onSelect: (targetValue: string, index: number) => void;
  onChangeField: (changeResult: any, index: number) => void;
  removeComponent: (index: number) => void;
  addComponent: () => void;
  onPageChanged: (page: number) => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        marginLeft: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "20px",
          marginBottom: "25px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>Form Components</Typography>
        <Pagination
          count={props.pageLength}
          page={props.currentPage}
          onChange={(evt, value) => {
            return props.onPageChanged(value);
          }}
        ></Pagination>
      </Box>
      <Box sx={{ height: "70vh", overflowY: "scroll" }}>
        {props.formComponent &&
          props.formComponent.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                <SelectInput
                  sx={{ width: "100%", marginBottom: "10px" }}
                  value={item.type}
                  onChange={(evt) => {
                    const targetValue = evt.target.value;
                    if (typeof targetValue === "string") {
                      props.onSelect(targetValue, index);
                    }
                  }}
                  options={options}
                />
                
                <DynamicFormIdentifier
                  onChangeField={(value) => props.onChangeField(value, index)}
                  currentFormComponent={item}
                  formComponent={props.formComponent}
                  index={index}
                ></DynamicFormIdentifier> 
              
                {
                  props.formComponent.length > 1 &&
                    <Card
                      sx={{ width: "100%", padding: "10px", marginBottom: "10px" }}
                    >
                      <Button onClick={() => props.removeComponent(index)}>
                        <Delete
                          sx={{ color: MAIN }}
                        ></Delete>
                      </Button>
                    </Card>
                }
              </Box>
            );
          })}
        <Box sx={{ padding: "10px", marginBottom: "10px" }}>
          <Button onClick={props.addComponent}>
            <Tooltip title="Add a new component">
              <Add sx={{ color: MAIN }}></Add>
            </Tooltip>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const ViewComponent = (props: {
  formView: any[];
  formTemplate: any;
  currentPage: number;
  pageLength: number;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignContent: "center",
        justifyContent: "center",
        marginTop: "10px",
      }}
    >
      <Card>
        <Box
          sx={{
            backgroundColor: MAIN,
            justifyContent: "center",
            width: "500px",
            height: "85vh",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "20px",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              {props.formTemplate.data.attributes.name}
            </Typography>
            <Typography sx={{ color: "white" }}>
              Step {props.currentPage} of {props.pageLength}{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              minHeight: "90%",
              backgroundColor: "white",
              borderRadius: "20px 20px 0 0px",
              padding: "20px",
            }}
          >
            {props.formView &&
              props.formView.map((item, index) => {
                const findOption: any = options.find(
                  (currItem) => currItem.value === item.type
                );
                if (findOption?.formView) {
                  const FormField = findOption.formView(index, item);
                  return FormField;
                }
                return <Box></Box>;
              })}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
