import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MAIN, MAIN_DARK } from '../values/Colors';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props: {isOpen: boolean, title: string, description: string, handleClose?: any, handleOpen?: any, handleConfirm: any, isLoading: boolean}) {
  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props.description}
          </Typography>
          <Box sx={{padding: 2}}></Box>
          <Box sx={{display: "flex", justifyContent: "right", gap: 2}}>
            {
              !props.isLoading ?
                <>
                  {
                    props.handleClose &&
                    <Button sx={{color: MAIN}} onClick={props.handleClose}>
                        Cancel
                    </Button>
                  }
                  <Button sx={{backgroundColor: MAIN, "&:hover": {background: MAIN_DARK}}} variant='contained' onClick={props.handleConfirm}>
                      Confirm
                  </Button>
                </>
                : <CircularProgress sx={{color: MAIN}} />
            }
          </Box>
        </Box>
      </Modal>
    </div>
  );
}