import { Box, Button, Card, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import SelectInput from "../SelectInput";
import { useEffect, useState } from "react";
import { IDynamicFormTitle } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormTitle";
import { MAIN } from "../../values/Colors";
import { IDynamicFormNationality } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormNationality";

export default function DynamicFormNationalityField(
  props: {
    content: IDynamicFormTitle;
    onChange(value: IDynamicFormNationality): void;
  }
) {
  const [fields, setFields] = useState<IDynamicFormNationality>({
    isRequired: props.content.isRequired || true,
  });

  function handleOnChange(key: string, value: string | boolean) {
    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  useEffect(() => {
    props.onChange(fields);
  }, [fields]);

  return (
    <>
      <Box sx={{ width: "100%", padding: "10px", marginBottom: "10px" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: MAIN,
                  "&.Mui-checked": {
                    color: MAIN,
                  },
                }}
                checked={props.content.isRequired}
                onChange={(evt) => {
                  const value = evt.target.checked;
                  handleOnChange("isRequired", value);
                }}
              />
            }
            label="Is Required"
          />
        </FormGroup>
      </Box>
    </>
  );
}
