import React, { useEffect, useState }from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridPaginationModel,
    GridCallbackDetails,
} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MAIN, MAIN_DARK } from '../values/Colors';
import { TextField } from '@mui/material';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';


interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}



export default function CustomEditableDataGrid(props: { initialState?: GridInitialStateCommunity, rowCount?: number, onPaginationModelChanged(model: GridPaginationModel, details: GridCallbackDetails<any>): void, rows: any[], columns: Array<GridColDef>, onItemDelete(item: any): any, onItemAdded(item: any, closeModal: Function): any, fields: Array<{name: string, label: string, type: 'picker' | 'select', enum?: string[]}>, AllowEditAction: boolean } ) {
    const [rows, setRows] = useState<any>(props.rows);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [addRecordModalVisible, setAddRecordModalVisible] = useState<boolean>(false)
    const [columns, setColumns] = useState<GridColDef[]>([
       
        ...props.columns,
    ])

    useEffect(() => {
        if (!props.AllowEditAction) {
            columns.unshift({
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    
                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={handleSaveClick(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />,
                        ];
                    }
    
                    return [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />,
                    ];
                },
            })
            setColumns(columns)
        }
    }, [])

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        const fetchItem = props.rows.find((item: any) => {
            return item.id === id
        })
        props.onItemAdded(fetchItem, () => {
            setAddRecordModalVisible(false)
        })
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        const fetchItem = props.rows.find((item: any) => {
            return item.id === id
        })
        props.onItemDelete(fetchItem)
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = props.rows.find((row: any) => row?.id === id);
        if (editedRow && editedRow!.isNew) {
            setRows(rows.filter((row: any) => row?.id !== id));
        }
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    function EditToolbar(props: EditToolbarProps) {
        const handleClick = () => {
            setAddRecordModalVisible(true)
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add record
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <AddRecordModal 
                isOpen={addRecordModalVisible} 
                title={"Add Record Modal"} 
                description='Edit edit' 
                handleClose={() => {
                    setAddRecordModalVisible(false)
                }}
                handleOpen={() => { }}
                handleConfirm={(item: any) => {
                    props.onItemAdded(item, () => {
                        setAddRecordModalVisible(false)
                    })
                }} 
                fields={props.fields} />

            <DataGrid
                initialState={props.initialState}
                rowCount={props.rowCount ? props.rowCount : 0}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                pageSizeOptions={[2,3,4,5, 100]}
                pagination
                onPaginationModelChange={props.onPaginationModelChanged}
                // slotProps={{
                //     toolbar: { setRows, setRowModesModel },
                // }}
            />
        </Box>
    );
}


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

function AddRecordModal(props: { isOpen: boolean, title: string, description: string, handleClose: any, handleOpen?: any, handleConfirm: any, fields: Array<{name: string, label: string, type: 'picker' | 'select', enum?: string[] }> }) {
    const [item, setItem] = React.useState({})
    
    return (
        <div>
            <Modal
                open={props.isOpen}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        {props.title}
                    </Typography>
                    <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                        {
                            props.fields.map((data, index) => {
                                return <TextField key={index} label={data.label} onChange={(evt) => setItem({...item, [data.name]: evt.target.value})}></TextField>
                            })
                        }
                    </Box>
                    <Box sx={{ padding: 2 }}></Box>
                    <Box sx={{ display: "flex", justifyContent: "right", gap: 2 }}>
                        <Button sx={{ color: MAIN }} onClick={props.handleClose}>
                            Cancel
                        </Button>
                        <Button sx={{ backgroundColor: MAIN, "&:hover": { background: MAIN_DARK } }} variant='contained' onClick={() => props.handleConfirm(item)}>
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}