import { Box, Chip, CircularProgress, IconButton, Input, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MAIN } from '../../values/Colors'
import { User } from '../../modules/users/User.Service'
import { AdminPanelSettingsSharp, Email, Google, Quiz, SupervisorAccountSharp } from '@mui/icons-material'
import LongMenu from '../../components/LongMenu'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useDebounce } from 'usehooks-ts'

export default function AuthenticationScreen() {
    const [isLoading, setLoading] = useState<boolean>(true)
    const [users, setUsers] = useState<any>([])
    const [paginationModel, setPaginationModel] = React.useState({
        page: 1,
        pageSize: 5,
    });
    const [searchEmail, setSearchEmail] = useState<string>()
    const debouncedSearchEmail = useDebounce(searchEmail, 500)

    const [rowCountState, setRowCountState] = React.useState(0);

    const navigate = useNavigate()

    async function getAllUsers(paginationPage: number = paginationModel.page) {

        await User.checkTokenValidity(navigate)
        const _getAllUsers: any = await User.getAllUsers(paginationPage, 5, '')

        if (_getAllUsers.data.length > 0) {
            let dataMemo: any = []
            // Flatten data
            await _getAllUsers.data.reduce(async (promise: any, item: any, index: number) => {
                await promise
                dataMemo.push({
                    id: item.id,
                    type: item.user,
                    ...item.attributes
                })
            }, Promise.resolve())
            setUsers(dataMemo)
        }
        const pagination = _getAllUsers.meta.pagination
        const totalDocuments = pagination.totalDocuments

        setRowCountState(totalDocuments)
        setLoading(false)

    }

    async function searchForEmail() {
        let dataMemo: any = []
    
        try {
            const result: any = await User.getUserByEmail(searchEmail)
            dataMemo.push({
                type: result.data.type,
                id: result.data.id,
                ...result.data.attributes
            })
        } catch(error: any) {
            if (error.name === 'USER_NOT_EXISTS') {
                dataMemo.push([])
            }
        }
        
        setLoading(false)
        setUsers(dataMemo)
    }
    
    useEffect(() => {
        getAllUsers(1)
    }, [])

    // Search for email
    useEffect(() => {
        (
            async () => {
                if (searchEmail) return searchForEmail()
                return getAllUsers(paginationModel.page)   
            }
        )()
    }, [debouncedSearchEmail])


    const columns: GridColDef[] = [
        {
            field: 'email',
            headerName: 'Email',
            width: 180,
        },
        {
            field: 'authProvider',
            headerName: 'Auth Provider',
            width: 140,
            renderCell: ({ id }) => {

                let authProviderInChip: any = []
                const item = users.find((data: any) => data.id === id)

                if (item.authProvider) {
                    const sortedRoles = item.authProvider.sort().reverse()
                    sortedRoles.forEach((role: string, index: number) => {
                        if (role === 'google') {
                            authProviderInChip.push(
                                <Tooltip key={index} title='Google'>
                                    <Google sx={{ color: MAIN }} />
                                </Tooltip>)
                        } else if (role === 'email/password') {
                            authProviderInChip.push(
                                <Tooltip key={index} title='Email/Password'>
                                    <Email sx={{ color: MAIN }} />
                                </Tooltip>)
                        }
                    })
    
                    return [
                        authProviderInChip
                    ]
                } 
                return []
            }
        },
        {
            field: 'accountStatus',
            headerName: 'Account Status',
            cellClassName: 'accountStatus',
            width: 140,
            renderCell: ({ id }) => {

                const item = users.find((data: any) => data.id === id)
                const accountStatus = item.accountStatus


                if (accountStatus === 'active') {
                    return [
                        <Chip key={id} label='Active'></Chip>
                    ]
                }
                if (accountStatus === 'disabled') {
                    return [
                        <Chip key={id} label='Disabled' variant='outlined'></Chip>
                    ]
                }
                return [
                    <></>

                ]
            }
        },
        {
            field: 'roles',
            headerName: 'Roles',
            cellClassName: 'roles',
            width: 120,
            renderCell: ({ id }) => {

                let rolesInChip: any = []
                const item = users.find((data: any) => data.id === id)

                const sortedRoles = item.roles.sort()
                sortedRoles.forEach((role: string, index: number) => {
                    if (role === 'developer') {
                        rolesInChip.push(
                            <Tooltip key={index} title='Developer'>
                                <AdminPanelSettingsSharp sx={{ color: MAIN }} />
                            </Tooltip>)
                    } else if (role === 'tester') {
                        rolesInChip.push(
                            <Tooltip key={index} title='Tester'>
                                <Quiz sx={{ color: MAIN }} />
                            </Tooltip>)
                    } else if (role === 'admin') {
                        rolesInChip.push(
                            <Tooltip key={index} title='Admin'>
                                <SupervisorAccountSharp sx={{ color: MAIN }} />
                            </Tooltip>)
                    }
                })

                return [
                    rolesInChip

                ]
            }
        },
        {
            field: 'createdAt',
            headerName: 'Account Created At',
            width: 200,
            cellClassName: 'createdAt',
            renderCell: ({ id }) => {

                const item = users.find((data: any) => data.id === id)
                const date = new Date(item.createdAt).toLocaleDateString()
                const time = new Date(item.createdAt).toLocaleTimeString()

                return [
                    <Typography key={id}>{date}, {time}</Typography>
                ]
            }
        },
        {
            field: 'action',
            headerName: '',
            width: 200,
            cellClassName: 'action',
            renderCell: ({ id }) => {

                const item = users.find((data: any) => data.id === id)

                let element: any

                if (!item.roles.includes('tester')) {
                    element =
                        <LongMenu key={id} options={[
                            {
                                name: 'Add as Tester',
                                action: async () => {
                                    await User.addAsTester(id.toString())
                                    if (searchEmail) return searchForEmail()
                                    await getAllUsers(paginationModel.page)
                                }
                            }
                        ]} />
                }

                if (item.roles.includes('tester')) {
                    element =
                        <LongMenu key={id} options={[
                            {
                                name: 'Remove as Tester',
                                action: async () => {
                                    await User.removeAsTester(id.toString())
                                    if (searchEmail) return searchForEmail()
                                    return await getAllUsers(paginationModel.page)
                                }
                            }
                        ]} />
                }
                return [
                    element
                ]
            }

        },
    ]

    return (
        <Box>
            {
                !isLoading
                    ?
                    <>
                    
                        <Box sx={{ height: 400, width: '100%' }}>
                            <TextField sx={{width: '100%'}} variant='outlined' placeholder='Search for email ( must be the full email )' onChange={(evt) => setSearchEmail(evt.target.value)}></TextField>
                            <DataGrid
                                rows={users}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            page: 0,
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                onPaginationModelChange={(model) => {
                                    setPaginationModel({
                                        pageSize: 5,
                                        page: model.page
                                    })
                                    getAllUsers(model.page + 1)
                                }}
                                rowCount={rowCountState}
                                filterMode='server'
                                paginationMode='server'
                                pageSizeOptions={[3, 5]}
                        
                            />
                        </Box>
                    </>
                    :
                    <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", height: "30vw" }}>
                        <CircularProgress sx={{ color: MAIN }} />
                    </Box>
            }
        </Box>
    )
}