import axios from "axios"
import { User } from "../users/User.Service"
import { Server } from "../../values/Server"
import { IFormTemplateAttributes, IFormTemplateAttributesUpdateable } from "../../common/interfaces/document_interfaces/FormTemplate"

class _FormTemplate {
    async getAllFormTemplates(page: number, limit: number, additionalQuery: string = '') {
        return new Promise(async (resolve, reject) => {
            const accessToken = await User.getAccessToken()
            axios({
                url: `${Server.API_ENDPOINT}/form-templates?pagination[page]=${page}&pagination[limit]=${limit}${additionalQuery}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }).then((snapshot) => {
                return resolve(snapshot.data)
            }).catch(async (error) => {
                if (error?.response?.data.error.name === 'TokenExpiredError') {
                    await User.refreshUser()
                    const data = await this.getAllFormTemplates(page, limit, additionalQuery)
                    return resolve(data)
                }
                return reject(error)
            })
        })
    }
    async getFormTemplate(id: string) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await User.getAccessToken()
            axios({
                url: `${Server.API_ENDPOINT}/form-template/${id}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }).then((snapshot) => {
                return resolve(snapshot.data)
            }).catch(async (error) => {
                if (error?.response?.data.error.name === 'TokenExpiredError') {
                    await User.refreshUser()
                    const data = await this.getFormTemplate(id)
                    return resolve(data)
                }
                return reject(error)
            })
        })
    }
    async setFormTemplateVisibility(id: string, visibility: 'public' | 'private') {
        return new Promise(async (resolve, reject) => {
            const accessToken = await User.getAccessToken()
            axios({
                url: `${Server.API_ENDPOINT}/form-template/${id}`,
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    data: {
                        visibility: visibility
                    }
                }
            }).then((snapshot) => {
                console.log(snapshot)
                return resolve(snapshot.data)
            }).catch(async (error) => {
                if (error?.response?.data.error.name === 'TokenExpiredError') {
                    await User.refreshUser()
                    const data = await this.setFormTemplateVisibility(id, visibility)
                    return resolve(data)
                }
                console.log(error)
                return reject(error)
            })
        })
    }
    async updateFormTemplate(id: string, updateableData: IFormTemplateAttributesUpdateable) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await User.getAccessToken()
            axios({
                url: `${Server.API_ENDPOINT}/form-template/${id}`,
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    data: updateableData
                }
            }).then((snapshot) => {
                console.log(snapshot)
                return resolve(snapshot.data)
            }).catch(async (error) => {
                if (error?.response?.data.error.name === 'TokenExpiredError') {
                    await User.refreshUser()
                    const data = await this.updateFormTemplate(id, updateableData)
                    return resolve(data)
                }
                console.log(error)
                return reject(error)
            })
        })
    }
}


export const FormTemplate = new _FormTemplate()