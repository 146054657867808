import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { IDynamicFormRadioGroup } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormRadioGroup";
import { MAIN } from "../../values/Colors";

export default function DynamicFormRadioGroup(props: {
  content: IDynamicFormRadioGroup;
}) {
  return (
    <Box sx={{ marginBottom: "10px" }}>
      <FormControl>
        <Box sx={{ display: "flex", gap: 1 }}>
          <FormLabel>{props.content.placeholder}</FormLabel>
          {props.content.isRequired && (
            <Typography sx={{ color: "red", fontWeight: "bold" }}>*</Typography>
          )}
        </Box>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {props.content.array?.length > 0 &&
            props.content.array.map((item, index) => {
              if (item.value && item.label) {
                return (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={
                      <Radio
                        sx={{
                          "&": {
                            color: "lightgray",
                          },
                          "&.Mui-checked": {
                            color: MAIN,
                          },
                        }}
                      />
                    }
                    label={item.label}
                  />
                );
              }
              return <Box></Box>;
            })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
