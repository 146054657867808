import { useEffect, useState } from "react";
import DynamicFormField from "../DynamicFormField";
import { IDynamicFormRadioGroup } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormRadioGroup";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, TextField, Tooltip, Typography } from "@mui/material";
import { MAIN } from "../../values/Colors";
import { PlaylistAdd, Remove } from "@mui/icons-material";
import { produce } from "immer";
import { toCamelCase } from "../../utils/TextFormat";

export default function DynamicFormRadioGroupField(
  props: {
    content: IDynamicFormRadioGroup;
    onChange(value: {
      placeholder?: string;
      value?: string;
      tipMessage?: string;
    }): any;
  }
) {
  const [fields, setFields] = useState<IDynamicFormRadioGroup>({
    placeholder: props.content.placeholder || "",
    variable: props.content.variable || "",
    array: props.content.array || [
      {
        label: "",
        value: "",
      },
    ],
    isRequired: props.content.isRequired || true,
    tipMessage: props.content.tipMessage || "",
  });

  function handleOnChange(key: string, value: any) {
    if (key === "placeholder") {
      const toCamel = toCamelCase(value);
      return setFields((prevState) => ({
        ...prevState,
        variable: toCamel,
        [key]: value,
      }));
    }

    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  function onAddOption() {
    setFields((prevState) => {
      return {
        ...prevState,
        array: [
          ...prevState.array,
          {
            label: "",
            value: "",
          },
        ],
      };
    });
  }

  function onRemoveOption(index: number) {
    const _array = [...fields.array];
    _array.splice(index);
    setFields((prevState) => {
      return {
        ...prevState,
        array: [..._array],
      };
    });
  }

  
  useEffect(() => {
    props.onChange(fields);
  }, [fields]);

  return (
    <>
      <DynamicFormField
        label="Placeholder"
        value={ props.content.placeholder}
        handleOnChange={handleOnChange}
      />
      <DynamicFormField
        label="Variable"
        value={ props.content.variable}
        handleOnChange={handleOnChange}
        typographyProps={{ disabled: true }}
      />
      <Box
        sx={{
          width: "100%",
          borderRadius: "5px",
          border: "1px solid lightgray",
          marginBottom: "10px",
        }}
      >
        <Box sx={{ padding: "10px", borderBottom: "1px solid lightgray" }}>
          <Typography sx={{ fontWeight: "bold" }}>Array</Typography>
        </Box>

        { props.content?.array && props.content.array.map((item, index) => {
          return (
            <ArrayFields
              key={index}
              label={item.label}
              value={item.value}
              onRemove={() => onRemoveOption(index)}
              onChange={(label, value) => {
                let _array = produce(fields.array, (draft) => {
                  draft[index] = {
                    label,
                    value,
                  };
                });
                setFields({
                  ...fields,
                  array: _array,
                });
              }}
            />
          );
        })}

        <Box sx={{ padding: "10px" }}>
          <Button onClick={onAddOption}>
            <Tooltip title="Add new option">
              <PlaylistAdd sx={{ color: MAIN }}></PlaylistAdd>
            </Tooltip>
          </Button>
        </Box>
      </Box>

      <DynamicFormField
        label="Tip Message"
        value={props.content.tipMessage}
        handleOnChange={handleOnChange}
      />

      <Box
        sx={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          border: "1px solid lightgray",
          borderRadius: "5px",
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: MAIN,
                  "&.Mui-checked": {
                    color: MAIN,
                  },
                }}
                checked={props.content.isRequired}
                onChange={(evt) => {
                  const value = evt.target.checked;
                  handleOnChange("isRequired", value);
                }}
              />
            }
            label="Is Required"
          />
        </FormGroup>
      </Box>
    </>
  );
}


const ArrayFields = (props: {
    label: string;
    value: string;
    onRemove: () => void;
    onChange: (label: string, value: string) => void;
  }) => {
    const [fields, setFields] = useState<{ label: string; value: string }>({
      label: props.label || "",
      value: props.value || "",
    });
  
    useEffect(() => {
      props.onChange(fields.label, fields.value);
    }, [fields]);
  
    return (
      <Box sx={{ padding: "10px", borderBottom: "1px solid lightgray" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            sx={{ marginTop: "10px" }}
            label="Label"
            placeholder="Label"
            value={fields.label}
            onChange={(evt) => {
              const value = evt.target.value;

              setFields((prevState) => {
                return {
                  ...prevState,
                  label: value,
                  value: toCamelCase(value)
                };
              });
            }}
          ></TextField>
          <TextField
            sx={{ marginTop: "10px" }}
            label="Value"
            placeholder="Value"
            value={fields.value}
            onChange={(evt) => {
              const value = evt.target.value;
              setFields((prevState) => {
                return {
                  ...prevState,
                  value: value,
                };
              });
            }}
          ></TextField>
          <Box sx={{ marginTop: "10px" }}>
            <Button onClick={props.onRemove}>
              <Tooltip title="Remove this option">
                <Remove sx={{ color: MAIN }}></Remove>
              </Tooltip>
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };