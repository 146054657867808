import { useEffect, useState } from "react";
import { IDynamicFormTitle } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormTitle";
import DynamicFormField from "../DynamicFormField";

export default function DynamicFormTitleField(props: {
  content: IDynamicFormTitle;
  onChange(value: {
    placeholder?: string;
    value?: string;
    tipMessage?: string;
  }): any;
}) {
  const [fields, setFields] = useState<IDynamicFormTitle>({
    value: props.content.value || "",
    subtitle: props.content.subtitle || "",
    tipMessage: props.content.tipMessage || "",
    isRequired: props.content.isRequired || false,
  });

  function handleOnChange(key: string, value: string) {
    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  useEffect(() => {
    props.onChange(fields);
  }, [fields]);

  return (
    <>
      <DynamicFormField
        label="Value"
        value={props.content.value}
        handleOnChange={handleOnChange}
      />
      <DynamicFormField
        label="Subtitle"
        value={props.content.subtitle}
        handleOnChange={handleOnChange}
      />
      <DynamicFormField
        label="Tip Message"
        value={props.content.tipMessage}
        handleOnChange={handleOnChange}
      />
    </>
  );
}
