import { useEffect, useState } from "react";
import DynamicFormField from "../DynamicFormField";
import { IDynamicFormDivider } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormDivider";

export default function DynamicFormDividerField(props: {
  content: IDynamicFormDivider;
  onChange(value: {
    placeholder?: string;
    value?: string;
    tipMessage?: string;
  }): any;
}) {
  const [fields, setFields] = useState<IDynamicFormDivider>({
    value: props.content.value || "",
  });

  function handleOnChange(key: string, value: string) {
    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  useEffect(() => {
    props.onChange(fields);
  }, [fields]);

  return (
    <>
      <DynamicFormField
        label="Value"
        value={props.content.value}
        handleOnChange={handleOnChange}
      />
    </>
  );
}
