import { Box, TextField, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { IDynamicFormTextbox } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormTextbox";

export default function DynamicFormTextbox(props: {
  content: IDynamicFormTextbox;
}) {
  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography variant="subtitle1">{props.content.placeholder}</Typography>
        {props.content.isRequired && (
          <Typography sx={{ color: "red", fontWeight: "bold" }}>*</Typography>
        )}
        {props.content.tipMessage && (
          <Tooltip title={props.content.tipMessage}>
            <Info sx={{ width: "20px", color: "gray" }}></Info>
          </Tooltip>
        )}
      </Box>
      <Box>
        <TextField
          inputProps={{ maxLength: props.content.maxLength }}
          placeholder={props.content.placeholderValue}
          multiline
          sx={{
            padding: "5px 10px",
            color: "darkgray",
            width: "100%",
            border: "none",
            "& fieldset": { border: "none" },
            borderRadius: "5px",
            backgroundColor: "whitesmoke",
          }}
        ></TextField>
      </Box>
    </Box>
  );
}
