import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Tooltip } from "@mui/material";

const ITEM_HEIGHT = 48;

export default function LongMenu(props: {
  options: {
    name: string;
    action: Function;
    menuProps?: any;
    disabledTooltipMessage?: string;
    enabledTooltipMessage?: string;
  }[];
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item: any) => {
    const pickedItem = props.options.find(
      (data: { name: string; action: any }) => data.name === item.name
    );
    pickedItem?.action();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {props.options.map((option, index) =>
          option.menuProps?.disabled === true ? (
            <Tooltip key={index}
              title={option.disabledTooltipMessage}>
              <MenuItem
                sx={{
                  color: "lightgray",
                }}
                selected={option.name === "Pyxis"}
              >
                {option.name}
              </MenuItem>
            </Tooltip>
          ) : option.enabledTooltipMessage ? (
            <Tooltip key={index}
              title={option.enabledTooltipMessage}>
              <MenuItem
                selected={option.name === "Pyxis"}
                onClick={() => handleClose(option)}
                {...option.menuProps}
              >
                {option.name}
              </MenuItem>
            </Tooltip>
          ) : (
            <MenuItem
              key={index}
              selected={option.name === "Pyxis"}
              onClick={() => handleClose(option)}
              {...option.menuProps}
            >
              {option.name}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
}
