import React, { useEffect, useState } from "react"
import { Box, Card, TextField, Button, Typography, CircularProgress } from "@mui/material"
import { ProgressButton } from "../components/ProgressButton"
import { MAIN } from "../values/Colors"
import { User } from "../modules/users/User.Service"
import { useNavigate } from "react-router-dom"


export default function PinScreen() {
    const [error, setError] = useState("")
    const [pin, setPin] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function confirmPin() {
        setLoading(true)
        try {
            await User.confirmPin(pin)
            navigate("/dashboard")
            return setLoading(false)
        } catch(error: any) {
            setLoading(false)
            if (error.name === 'INVALID_CREDENTIALS')
                return setError(`Pin not match, you have ${error.availableAttempts} available attempts`)
            if (error.name === 'MAX_PIN_ATTEMPT_REACHED')
                return setError(`Maximum attempt reached, try again tomorrow`)
        }
    }

    async function getCurrentUser() {
        const user = await User.getAuthenticationToken()

        if (!user) return navigate("/")
    }

    useEffect(() => {
        getCurrentUser()
    }, [])

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", background: MAIN}}>
            <Card sx={{padding: "50px", borderRadius: 5}}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "40vw", maxWidth: "500px", flexDirection: "column", gap: 2}}>
                    <img src={require("../assets/images/bilis_logo.png")} style={{width: "200px", marginBottom: "30px"}}></img>
                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: 2}}>
                        {
                            !error 
                                ? <TextField 
                                    value={pin} 
                                    label="Pin" 
                                    type="password" 
                                    inputProps={{ maxLength: 4 }}
                                    onChange={(evt) => setPin(evt.target.value)}
                                    >
                                    </TextField>
                                : <TextField 
                                    value={pin} 
                                    error 
                                    label="Pin" 
                                    helperText={error} 
                                    type="password" 
                                    inputProps={{ maxLength: 4 }}
                                    onChange={(evt) => setPin(evt.target.value)}>
                                    </TextField>
                        }
                        <ProgressButton label={"Confirm"} isLoading={loading} onClick={confirmPin}></ProgressButton>
    
                    </Box>
                </Box>
            </Card>
        </Box>
    )
}