import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { MAIN } from "../../../values/Colors";
import { useEffect, useState } from "react";
import { BarangayRegistration } from "../../../modules/barangay_registrations/BarangayRegistration.Service";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import LongMenu from "../../../components/LongMenu";
import BasicModal from "../../../components/Modal";

export default function BarangayRegistrationScreen() {
  const [loading, setLoading] = useState<boolean>(true);
  const [barangayRegistrations, setBarangayRegistrations] = useState<any>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 5,
  });
  const [rowCountState, setRowCountState] = useState<number>();
  const [barangayApproveModalVisible, setRegistrationApproveModalVisible] = useState<boolean>(false);
  const [barangayDeclineModalVisible, setRegistrationDeclineModalVisible] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [pickedItem, setPickedItem] = useState<any>()

  async function getBarangayRegistrations(paginationPage: number = paginationModel.page) {
    const _getBarangayRegistrations: any = await BarangayRegistration.getAllBarangayRegistrations(paginationPage, 5);

    let dataMemo: any = [];
    if (_getBarangayRegistrations.data?.length > 0) {
      // Flatten data
      await _getBarangayRegistrations.data.reduce(
        async (promise: any, item: any, index: number) => {
          await promise;
          dataMemo.push({
            id: item.id,
            type: item.user,
            region: item.attributes.region.Name,
            province: item.attributes.province.Name,
            municipal: item.attributes.municipal.Name,
            barangay: item.attributes.barangay.Name,
            email: item.attributes.ownerEmail,
            createdAt: item.attributes.createdAt,
          });
        },
        Promise.resolve()
      );
      setBarangayRegistrations(dataMemo);
    }

    if (_getBarangayRegistrations.data?.length === 0) {
      setBarangayRegistrations(dataMemo);
    }
    
    const pagination = _getBarangayRegistrations.meta.pagination;
    const totalDocuments = pagination.totalDocuments;

    setRowCountState(totalDocuments);
    setLoading(false);
  }

  const columns: GridColDef[] = [
    {
      field: "region",
      headerName: "Region",
      width: 180,
    },
    {
      field: "province",
      headerName: "Province",
      width: 180,
    },
    {
      field: "municipal",
      headerName: "Municipal",
      width: 180,
    },
    {
      field: "barangay",
      headerName: "Barangay",
      width: 180,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
    },
    {
      field: "createdAt",
      headerName: "Registration Created At",
      width: 200,
      cellClassName: "createdAt",
      renderCell: ({ id }) => {
        const item = barangayRegistrations.find((data: any) => data.id === id);
        const date = new Date(item.createdAt).toLocaleDateString();
        const time = new Date(item.createdAt).toLocaleTimeString();

        return [
          <Typography key={id}>
            {date}, {time}
          </Typography>,
        ];
      },
    },
    {
      field: "action",
      headerName: "",
      width: 200,
      cellClassName: "action",
      renderCell: ({ id }) => {
        const item = barangayRegistrations.find((data: any) => data.id === id)
        let element: any;
        element = (
          <LongMenu
            key={id}
            options={[
              {
                name: "Approve",
                action: async () => {
                  setRegistrationApproveModalVisible(true);
                  setPickedItem(item)
                },
              },
              {
                name: "Decline",
                action: async () => {
                  setRegistrationDeclineModalVisible(true);
                  setPickedItem(item)
                },
              },
            ]}
          />
        );
        return [element];
      },
    },
  ];

  useEffect(() => {
    getBarangayRegistrations();
  }, []);

  const ModalComponents = () => {
    return (
      <>
        <BasicModal
          isLoading={modalLoading}
          title="Approve Barangay Registration Request?"
          description={`You are about to register barangay Concepcion with itdept@digitalinnov.com as the first Barangay Admin, make sure you check the details correctly before confirming the request.`}
          isOpen={barangayApproveModalVisible}
          handleClose={() => {
            setRegistrationApproveModalVisible(false);
          }}
          handleConfirm={async () => {}}
        />
        <BasicModal
          isLoading={modalLoading}
          title="Declining Barangay Registration Request?"
          description={`You are about to decline a barangay registration request the following action cannot be undone after confirmation.`}
          isOpen={barangayDeclineModalVisible}
          handleClose={() => {
            setRegistrationDeclineModalVisible(false);
          }}
          handleConfirm={async () => {
            setModalLoading(true)
            await BarangayRegistration.declineBarangayRegistration(pickedItem.id)
            setRegistrationDeclineModalVisible(false);
            await getBarangayRegistrations(paginationModel.page)
            setModalLoading(false)
          }}
        />
      </>
    );
  };

  return (
    <Box>
      {!loading ? (
        <>
          <ModalComponents />
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={barangayRegistrations}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    page: 0,
                    pageSize: 5,
                  },
                },
              }}
              onPaginationModelChange={(model) => {
                setPaginationModel({
                  pageSize: 5,
                  page: model.page,
                });
                //getAllUsers(model.page + 1);
              }}
              rowCount={rowCountState}
              filterMode="server"
              paginationMode="server"
              pageSizeOptions={[3, 5]}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "30vw",
          }}
        >
          <CircularProgress sx={{ color: MAIN }} />
        </Box>
      )}
    </Box>
  );
}
