import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { ISelectInputComponentBase, ISelectInputOption } from '../common/interfaces/SelectInputComponentInterface';

export default function SelectInput(props: ISelectInputComponentBase) {

    return (
        <>
            <InputLabel id="select-label">{props.label}</InputLabel>
            <Select
                labelId="select-label"
                id="select-label"
                {...props}
            >
                {
                    props.options && props.options.map((item: ISelectInputOption, index: number) => {
                        return (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        )
                    })
                }
            </Select>
        </>
    );
}