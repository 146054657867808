import React from 'react'
import { Button, Box, CircularProgress } from "@mui/material" 
import { MAIN, MAIN_DARK } from '../values/Colors'


export const ProgressButton = (props: {label: string, isLoading: boolean, onClick: Function}) => {
    return (
        <>
            {
                !props.isLoading 
                    ?   <Button sx={{background: MAIN, "&:hover": {background: MAIN_DARK}}} variant="contained" onClick={() => props.onClick()}>{props.label}</Button>
                    : 
                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress sx={{color: MAIN}} />
                        </Box>

            }
        </>
    )
}