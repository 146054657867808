import React, { ReactElement, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { CustomDrawer } from '../components/CustomDrawer'
import { Route, Routes, useNavigate } from 'react-router-dom'
import AnalyticScreen from './dashboard_screens/AnalyticScreen'
import { Mail, Analytics, Group, Warehouse, Contacts, RateReview, Notes } from '@mui/icons-material';
import { INavigationTab, INonListedTab } from '../common/interfaces/DrawerInterface'
import { MAIN } from '../values/Colors'
import UserAnalyticsScreen from './dashboard_screens/analytic_screens/UserAnalyticsScreen'
import BarangayRegisteredAnalyticsScreen from './dashboard_screens/analytic_screens/BarangayRegisteredAnalyticsScreen'
import AuthenticationScreen from './dashboard_screens/AuthenticationScreen'
import ApplicationScreen from './dashboard_screens/ApplicationScreen'
import SettingsScreen from './dashboard_screens/SettingsScreen'
import BarangayRegistrationScreen from './dashboard_screens/registration_screens/BarangayRegistrationScreen'
import FormTemplateScreen from './dashboard_screens/form_template_screens/FormTemplateScreen'
import FormTemplateOverviewScreen from './dashboard_screens/form_template_screens/FormTemplateOverviewScreen'
import { FormTemplateInformationScreen } from './dashboard_screens/form_template_screens/FormTemplateInformationScreen'

export default function DashboardScreen() {
    const [title, setTitle] = useState("Dashboard")
    const navigate = useNavigate()
    const [pages, setPages] = useState<Array<{path: string, element: ReactElement | any}>>([])

    const navigationTabs: Array<INavigationTab> = [
        {
            name: 'Analytics',
            routePath: '/analytics',
            icon: <Analytics sx={{ color: MAIN }} />,
            subNavigationTabs: [
                {
                    name: 'Users',
                    element: <UserAnalyticsScreen />,
                    icon: <Group sx={{ color: MAIN }} />,
                    routePath: '/analytics/user-registered',
                    onClick: () => {
                        navigate('/dashboard/analytics/user-registered')
                        setTitle('User Analytics')
                    }
                },
                {
                    name: 'Barangay Registered',
                    element: <BarangayRegisteredAnalyticsScreen />,
                    icon: <Warehouse sx={{ color: MAIN }} />,
                    routePath: '/analytics/barangay-registered',
                    onClick: () => {
                        navigate('/dashboard/analytics/barangay-registered')
                        setTitle('Barangay Registered Analytics')
                    }
                }
            ]
        },
        {
            name: 'Registration',
            element: <AnalyticScreen />,
            routePath: '/analytics',
            icon: <Notes sx={{ color: MAIN }} />,
            subNavigationTabs: [
                {
                    name: 'Barangay Registrations',
                    element: <BarangayRegistrationScreen />,
                    routePath: '/registration/barangay-registrations',
                    icon: <RateReview sx={{ color: MAIN }} />,
                    onClick: () => {
                        navigate('/dashboard/registration/barangay-registrations')
                        setTitle('Barangay Registration')
                    }
                }
            ]
        },
        {
            name: 'Authentication',
            element: <AuthenticationScreen />,
            routePath: '/authentication',
            icon: <Contacts sx={{ color: MAIN }} />,
            onClick: () => {
                navigate('/dashboard/authentication')
                setTitle('Authentication')
            }
        },
        {
            name: 'Applications',
            element: <ApplicationScreen />,
            routePath: '/applications',
            icon: <Mail sx={{ color: MAIN }} />,
            onClick: () => {
                navigate('/dashboard/applications')
                setTitle('Application')
            }
        },
        {
            name: 'Form Template',
            element: <FormTemplateScreen />,
            routePath: '/form-template',
            icon: <Mail sx={{ color: MAIN }} />,
            onClick: () => {
                navigate('/dashboard/form-template')
                setTitle('Form Template')
            }
        },
        {
            name: 'Settings',
            element: <SettingsScreen />,
            routePath: '/settings',
            icon: <Mail sx={{ color: MAIN }} />,
            onClick: () => {
                navigate('/dashboard/settings')
                setTitle('Settings')
            }
        }
    ]

    const nonListedTab: Array<INonListedTab> = [
        {
            name: 'FormTemplateVisibilityOverview',
            routePath: '/form-template/overview',
            element: <FormTemplateOverviewScreen />
        },
        {
            name: 'FormTemplateInformation',
            routePath: '/form-template/information',
            element: <FormTemplateInformationScreen />
        }
    ]


    function getAllRoutes() {
        let pathMemo: Array<{path: string, element: any}> = []
        navigationTabs.map((item, index) => {
            item.subNavigationTabs?.map((subItem, subIndex) => {
                pathMemo.push({
                    path: subItem.routePath,
                    element: subItem.element
                })
            })
            pathMemo.push({
                path: item.routePath,
                element: item.element
            })
        })
        setPages(pathMemo)
    }

 

    useEffect(() => {
        getAllRoutes()
    }, [])


    return (
        <Box>
            <CustomDrawer title={title} navigationTabs={navigationTabs}>
                <Routes>
                    {
                        pages?.length > 0 &&
                            pages?.map((item, index) => {
                                return <Route key={index} path={item.path} element={item.element}></Route>
                            })
                    }
                    {
                        nonListedTab?.length > 0 &&
                        nonListedTab?.map((item, index) => {
                                return <Route key={index} path={item.routePath} element={item.element}></Route>
                            })
                    }
                </Routes>
            </CustomDrawer>
        </Box>
    )
}