import axios from "axios"
import { Server } from "../../values/Server"
import { User } from "../users/User.Service"

const resourcePath = 'application'

class _Application {
    async getApplication(os: 'android' | 'ios') {
        return new Promise((resolve, reject) => {
            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/${os}`,
                method: 'GET',
            }).then((snapshot) => {
                return resolve(snapshot.data.data)
            }).catch((error) => {
                return reject(error)
            })
        })
    }

    async addApplicationAvailableVersion(os: 'android' | 'ios', version: string) {
        return new Promise(async (resolve, reject) => {
            const accessToken = await User.getAccessToken()

            await User.getCurrentUser()

            const getVersion: any = await this.getApplication(os)

            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/${os}`,
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    data: {
                        version: [...getVersion?.attributes?.version, version]
                    }
                }
            }).then((snapshot) => {
                return resolve(snapshot.data.data)
            }).catch((error) => {
                return reject(error)
            })
        })
    }

    async removeApplicationAvailableVersion(os: 'android' | 'ios', version: string) {
        return new Promise(async (resolve, reject) => {
            await User.getCurrentUser()
            const accessToken = await User.getAccessToken()

            const getVersion: any = await this.getApplication(os)

            const filterVersion = getVersion.attributes.version.filter((item: any) => item !== version)
            axios({
                url: `${Server.API_ENDPOINT}/${resourcePath}/${os}`,
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    data: {
                        version: filterVersion
                    },
                }
            }).then((snapshot) => {
                return resolve(snapshot.data.data)
            }).catch((error) => {
                return reject(error)
            })
        })
    }
}


export const Application = new _Application()