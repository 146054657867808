import { Box, Typography } from "@mui/material";
import SelectInput from "../SelectInput";
import { useState } from "react";
import { IDynamicFormIdentificationDocument } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormIdentificationDocument";

export default function DynamicFormIdentificationDocument(props: {
  content: IDynamicFormIdentificationDocument;
}) {
  const [selectedValue, setSelectedValue] = useState<string>("");

  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", border: '1px solid gray', padding: '10px', borderRadius: '5px', borderStyle: "dashed" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{fontWeight: "bold"}} variant="body1">Update Document</Typography>
            {props.content.isRequired && (
            <Typography sx={{ color: "red", fontWeight: "bold" }}>*</Typography>
            )}
        </Box>
        <Typography variant="body1">If you wish to make updates to your document, just tap here.</Typography>
      </Box>
    </Box>
  );
}
