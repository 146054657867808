import { Box, Button, Card, FormHelperText, TextField } from "@mui/material"
import SelectInput from "../SelectInput"
import { useEffect, useState } from "react"
import { IDynamicFormValue } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormValue"
import { toCamelCase } from "../../utils/TextFormat"
import DynamicFormField from "../DynamicFormField"


export default function DynamicFormValueField(props : { content: IDynamicFormValue, onChange(value: { placeholder?: string, value?: string, tipMessage?: string }): any } ) {

    const [fields, setFields] = useState<IDynamicFormValue>({
        placeholder: props.content.placeholder || '',
        value: props.content.value || '',
        variable: toCamelCase(props.content.placeholder) || '',
        tipMessage: props.content.tipMessage || '',
    })

    function handleOnChange(key: string, value: string) {
        if (key === 'placeholder') {
            const toCamel = toCamelCase(value)
            return setFields(prevState => ({
                ...prevState,
                variable: toCamel,
                [key]: value
            }))
        }

        setFields(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    useEffect(() => {
        props.onChange(fields)
    }, [fields])

    return (
        <>
            <DynamicFormField label="Placeholder" value={props.content.placeholder} handleOnChange={handleOnChange}/>
            <TextField sx={{ width: '100%', marginBottom: '5px' }} variant="outlined" value={props.content.variable} label="Variable" placeholder="Variable" onChange={(evt) => handleOnChange('variable', evt.target.value)} disabled></TextField>
            {/* <DynamicFormField label="Value" value={fields.value} handleOnChange={handleOnChange}/> */}
            <DynamicFormField label="Tip Message" value={props.content.tipMessage} handleOnChange={handleOnChange}/>
        </>
    )
}

