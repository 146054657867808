import { Box, FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { toCamelCase } from "../../utils/TextFormat";
import DynamicFormField from "../DynamicFormField";
import { IDynamicFormCalendar } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormCalendar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { MAIN } from "../../values/Colors";

export default function DynamicFormCalendarField(props: {
  content: IDynamicFormCalendar;
  onChange(value: {
    placeholder?: string;
    value?: string;
    tipMessage?: string;
  }): void;
}) {
  
  const [fields, setFields] = useState<IDynamicFormCalendar>({
    isRequired: props.content.isRequired || true,
    placeholder: props.content.placeholder || "",
    placeholderValue: props.content.placeholderValue || "",
    tipMessage: props.content.tipMessage || "",
    variable: props.content.variable || "",
    maximumDate: props.content.maximumDate || null,
    minimumDate: props.content.minimumDate || null,
  });

  function handleOnChange(key: string, value: any) {
    if (key === "placeholder") {
      const toCamel = toCamelCase(value);
      return setFields((prevState) => ({
        ...prevState,
        variable: toCamel,
        [key]: value,
      }));
    }

    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  useEffect(() => {
    props.onChange(fields);
  }, [fields]);

  return (
    <>
      <DynamicFormField
        label="Placeholder"
        value={props.content.placeholder}
        handleOnChange={handleOnChange}
      />
      <DynamicFormField
        label="Variable"
        value={props.content.variable}
        handleOnChange={handleOnChange}
        typographyProps={{ disabled: true }}
      />
      <Box sx={{ width: "100%", marginBottom: "10px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            defaultValue={props.content.maximumDate}
            label="Maximum Date"
            sx={{ width: "100%" }}
            onChange={(date) => {
              const getDate = dayjs(date).toISOString();
              handleOnChange("maximumDate", getDate);
            }}
          ></DatePicker>
        </LocalizationProvider>
      </Box>
      <Box sx={{ width: "100%", marginBottom: "10px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            defaultValue={props.content.minimumDate}
            label="Minimum Date"
            sx={{ width: "100%" }}
            onChange={(date) => {
              const getDate = dayjs(date).toISOString();
              handleOnChange("minimumDate", getDate);
            }}
          ></DatePicker>
        </LocalizationProvider>
      </Box>
      <DynamicFormField
        label="Placeholder Value"
        value={props.content.placeholderValue}
        handleOnChange={handleOnChange}
      />
      <Box
        sx={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          border: "1px solid lightgray",
          borderRadius: "5px",
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: MAIN,
                  "&.Mui-checked": {
                    color: MAIN,
                  },
                }}
                checked={props.content.isRequired}
                onChange={(evt) => {
                  const value = evt.target.checked;
                  handleOnChange("isRequired", value);
                }}
              />
            }
            label="Is Required"
          />
        </FormGroup>
      </Box>
    </>
  );
}
