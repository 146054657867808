import { Box, Typography } from "@mui/material";
import { IDynamicFormNationality } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormNationality";
import SelectInput from "../SelectInput";
import { useState } from "react";

export default function DynamicFormNationality(props: {
  content: IDynamicFormNationality;
}) {
  const [selectedValue, setSelectedValue] = useState<string>("");

  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Typography variant="body1">Nationality</Typography>
        {props.content.isRequired && (
          <Typography sx={{ color: "red", fontWeight: "bold" }}>*</Typography>
        )}
      </Box>
      <SelectInput
        sx={{
          padding: "5px 10px",
          color: "darkgray",
          width: "100%",
          border: "none",
          "& fieldset": { border: "none" },
          borderRadius: "5px",
          backgroundColor: "whitesmoke",
        }}
        value={selectedValue}
        options={[]}
        onChange={(evt) => {
          const targetValue = evt.target.value;
          if (typeof targetValue === "string") {
            setSelectedValue(targetValue);
          }
        }}
      ></SelectInput>
    </Box>
  );
}
