import { Box, Divider } from "@mui/material";
import { IDynamicFormDivider } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormDivider";

export default function DynamicFormDivider(props: {
  content: IDynamicFormDivider;
}) {
  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Divider sx={{ color: "gray", fontSize: "15px" }}>{props.content.value}</Divider>
    </Box>
  );
}
