import React from "react"
import { KeyboardBackspaceSharp } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"
import { MAIN } from "../values/Colors"

export function PageHeader(props: { onBackButtonClicked: () => void, title: string }) {
    return (
        <Box sx={{ backgroundColor: MAIN, borderRadius: "0 0 15px 15px", padding: "15px" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center" }}>
                <Button
                    onClick={props.onBackButtonClicked}>
                    <KeyboardBackspaceSharp sx={{ color: "white" }}>
                    </KeyboardBackspaceSharp>
                </Button>
                <Typography sx={{ color: "white", fontWeight: "bold" }}>{props.title}</Typography>
            </Box>
        </Box>
    )
}