import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { FormTemplate } from "../../../modules/form_templates/FormTemplate.Service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MAIN } from "../../../values/Colors";
import { CustomDropzone } from "../../../components/CustomDropzone";
import { KeyboardBackspaceSharp } from "@mui/icons-material";
import { PageHeader } from "../../../components/PageHeader";
import { produce } from "immer";
import { IFormTemplateAttributesUpdateable } from "../../../common/interfaces/document_interfaces/FormTemplate";
import BasicModal from "../../../components/Modal";

export function FormTemplateInformationScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [formTemplate, setFormTemplate] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [changes, setChanges] =
    useState<IFormTemplateAttributesUpdateable | null>(null);
  const [modalSaveChangesVisible, setModalSaveChangesVisible] =
    useState<boolean>(false);
 const [modalSaveChangesLoading, setModalSaveChangesLoading] =
    useState<boolean>(false);
  const navigate = useNavigate();

  async function getFormTemplate() {
    const formTemplateId = searchParams.get("id");

    if (formTemplateId) {
      const _getFormTemplate: any = await FormTemplate.getFormTemplate(
        formTemplateId
      );
      setFormTemplate(_getFormTemplate.data);
      setLoading(false);
    }
  }

  async function onSave() {
    setModalSaveChangesVisible(true);
    // await FormTemplate.updateFormTemplate(
    //   formTemplate.id,
    //   changes
    // );
  }

  useEffect(() => {
    getFormTemplate();
  }, []);

  return (
    <Box sx={{ marginBottom: "20vh" }}>
      <BasicModal
        title="Are you sure you want to save changes?"
        description="Save changes corresponding to this form template"
        handleClose={() => {
          setModalSaveChangesVisible(false);
        }}
        handleConfirm={async () => {
          if (changes) {
            setModalSaveChangesLoading(true)
            await FormTemplate.updateFormTemplate(formTemplate.id, changes);
          }
          setModalSaveChangesVisible(false);
          setModalSaveChangesLoading(false)
          setChanges(null);
        }}
        isOpen={modalSaveChangesVisible}
        isLoading={modalSaveChangesLoading}
      />
      <PageHeader
        title="Edit the Form Template Information"
        onBackButtonClicked={() => {
          navigate("/dashboard/form-template");
        }}
      />

      {!isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Box sx={{ width: "70%" }}>
            <Box sx={{ marginTop: "10px" }}>
              <Typography sx={{ fontWeight: "bold" }} variant="body1">
                Image
              </Typography>
              <CustomDropzone
                allowedTypes={["image/"]}
                onDrop={(params) => {
                  console.log(params);
                }}
                onError={(error) => {
                  console.log(error);
                  alert(error.message);
                }}
              ></CustomDropzone>
            </Box>

            <Box sx={{ marginTop: "10px" }}>
              <Typography sx={{ fontWeight: "bold" }} variant="body1">
                Name
              </Typography>
              <TextField
                sx={{ width: "100%" }}
                defaultValue={formTemplate?.attributes.name}
                onChange={(evt) => {
                  const text: string = evt.target.value;
                  if (typeof text === "string") {
                    const item = produce(formTemplate, (draft: any) => {
                      draft.attributes.name = text;
                      return draft;
                    });

                    setChanges({
                      ...changes,
                      name: text,
                    });
                    setFormTemplate(item);
                  }
                }}
              ></TextField>
            </Box>

            <Box sx={{ marginTop: "10px" }}>
              <Typography sx={{ fontWeight: "bold" }} variant="body1">
                Description
              </Typography>
              <Box
                sx={{
                  height: "50vh",
                  overflowY: "scroll",
                  border: "1px solid gray",
                  borderRadius: "5px",
                }}
              >
                <TextField
                  sx={{ width: "100%", "& fieldset": { border: "none" } }}
                  multiline
                  defaultValue={formTemplate?.attributes.description || ""}
                  onChange={(evt) => {
                    const text: string = evt.target.value;
                    if (typeof text === "string") {
                      const item = produce(formTemplate, (draft: any) => {
                        draft.attributes.description = text;
                        return draft;
                      });
                      setChanges({
                        ...changes,
                        description: text,
                      });
                      setFormTemplate(item);
                    }
                  }}
                ></TextField>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "right",
              }}
            >
                {
                    !changes
                    ?
                        <Button
                            sx={{
                            backgroundColor: MAIN,
                            "&:hover": { backgroundColor: MAIN },
                            }}
                            variant="contained"
                            disabled
                        >
                            Save
                        </Button>
                    :
                        <Button
                            sx={{
                            backgroundColor: MAIN,
                            "&:hover": { backgroundColor: MAIN },
                            }}
                            variant="contained"
                            onClick={onSave}
                        >
                            Save
                        </Button>
                }
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "30vw",
          }}
        >
          <CircularProgress sx={{ color: MAIN }} />
        </Box>
      )}
    </Box>
  );
}
