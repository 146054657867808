



export async function ComponentScanForContent(componentPages: Array<object[]>) {
    let contentMemo: any = {}


    await componentPages.reduce(async (promise: any, components: any) => {
        await promise
        await components.reduce(async (promise: any, component: any) => {
            await promise
            const componentType = component?.type
            const componentVariable = component?.variable
            const componentIsRequired = component?.isRequired

            if (componentVariable && !["formValue"].includes(componentType)) {
                let currentContent = {
                    [componentVariable]: {
                        type: "string",
                        isRequired: componentIsRequired
                    }
                }
    
                contentMemo = {
                    ...contentMemo,
                    ...currentContent
                }
    
                switch(componentType) {
                    case "calendar": 
                        contentMemo[componentVariable].type = "date"
                        break
                    default:
                        contentMemo[componentVariable].type = "string"
                }
            }
        }, Promise.resolve())
    }, Promise.resolve())

    return Promise.resolve(contentMemo)
}