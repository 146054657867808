import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Button, Card } from "@mui/material";
import { MAIN } from "../values/Colors";
import { ProgressButton } from "../components/ProgressButton";
import { User } from "../modules/users/User.Service";
import { useNavigate } from "react-router-dom";


export default function LoginScreen() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function login() {
        setLoading(true)
        if (!email || !password) 
            return setError("Invalid email or password")

        try {
            await User.loginUser(email, password)
            navigate("/confirm-email-authorization")
            setLoading(false)
        } catch(error: any) {
            setLoading(false)
            if (error.name === "INVALID_CREDENTIALS") 
                return setError(`Invalid email or password, you have ${error.availableAttempts} available attempts`)
            if (error.name === "MAX_LOGIN_ATTEMPT_REACHED") 
                return setError(`Maximum attempt reached, try again tomorrow`)
                
        }
    }


    async function getCurrentUser() {
        const authenticationToken = await User.getAuthenticationToken()
        if (authenticationToken) return navigate("/confirm-email-authorization")
    }

    useEffect(() => {
        getCurrentUser()
    }, [])

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", background: MAIN}}>
            <Card sx={{padding: "50px", borderRadius: 5}}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "40vw", maxWidth: "500px", flexDirection: "column", gap: 2}}>
                    <img src={require("../assets/images/bilis_logo.png")} style={{width: "200px", marginBottom: "30px"}}></img>
                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: 2}}>
                        {
                            !error 
                                ?
                                    <>
                                        <TextField value={email} label="Email" onChange={(evt) => setEmail(evt.target.value)}></TextField>
                                        <TextField value={password} label="Password" type="password" onChange={(evt) => setPassword(evt.target.value)}></TextField>
                                    </>
                                :
                                    <>
                                        <TextField value={email} error label="Email" onChange={(evt) => setEmail(evt.target.value)}></TextField>
                                        <TextField value={password} error helperText={error} label="Password" type="password" onChange={(evt) => setPassword(evt.target.value)}></TextField>
                                    </>
                        }
             
                        <ProgressButton label={"Login"} isLoading={loading} onClick={login}></ProgressButton>
                    </Box>
                </Box>
            </Card>
        </Box>
    )
}