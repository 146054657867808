import { Box, FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { toCamelCase } from "../../utils/TextFormat";
import { IDynamicFormTextbox } from "../../common/interfaces/dynamic_form_interfaces/DynamicFormTextbox";
import DynamicFormField from "../DynamicFormField";
import { MAIN } from "../../values/Colors";

export default function DynamicFormTextboxField(
  props: {
    content: IDynamicFormTextbox;
    onChange(value: {
      placeholder?: string;
      value?: string;
      tipMessage?: string;
    }): void;
  }
) {
  const [fields, setFields] = useState<IDynamicFormTextbox>({
    acceptedValues: props.content.acceptedValues || '',
    isRequired: props.content.isRequired || false,
    maxLength: props.content.maxLength || 100,
    placeholder: props.content.placeholder || '',
    placeholderValue: props.content.placeholderValue || '',
    tipMessage: props.content.tipMessage || '',
    variable: props.content.variable ||  ''
  });

  function handleOnChange(key: string, value: any) {
    if (key === "placeholder") {
      const toCamel = toCamelCase(value);
      return setFields((prevState) => ({
        ...prevState,
        variable: toCamel,
        [key]: value,
      }));
    }

    setFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }


  useEffect(() => {
    props.onChange(fields);
  }, [fields]);

  return (
    <>
      <DynamicFormField
        label="Placeholder"
        value={props.content.placeholder}
        handleOnChange={handleOnChange}
      />
      <DynamicFormField
        label="Variable"
        value={props.content.variable}
        handleOnChange={handleOnChange}
        typographyProps={{ disabled: true }}
      />
      <DynamicFormField
        label="Accepted Values"
        value={props.content.acceptedValues}
        handleOnChange={handleOnChange}
      />
      <DynamicFormField
        label="Max Length"
        value={props.content.maxLength?.toString()}
        handleOnChange={handleOnChange}
        typographyProps={{ type: "number" }}
      />
      <DynamicFormField
        label="Placeholder Value"
        value={props.content.placeholderValue}
        handleOnChange={handleOnChange}
      />
      <DynamicFormField
        label="Tip Message"
        value={props.content.tipMessage}
        handleOnChange={handleOnChange}
      />
      <Box sx={{ width: "100%", padding: "10px", marginBottom: "10px" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: MAIN,
                  "&.Mui-checked": {
                    color: MAIN,
                  },
                }}
                checked={props.content.isRequired}
                onChange={(evt) => {
                    const value = evt.target.checked
                    handleOnChange('isRequired', value)
                }}
              />
            }
            label="Is Required"
          />
        </FormGroup>
      </Box>
    </>
  );
}
