import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormTemplate } from "../../../modules/form_templates/FormTemplate.Service";
import { MAIN, MAIN_DARK } from "../../../values/Colors";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import LongMenu from "../../../components/LongMenu";
import BasicModal from "../../../components/Modal";
import { useNavigate } from "react-router";
import { AddCircleOutline } from "@mui/icons-material";
import { DataGridToolbar } from "../../../components/DataGridToolbar";

export default function FormTemplateScreen() {
  const [formTemplates, setFormTemplates] = useState<any>();
  const [rowCountState, setRowCountState] = useState<number>();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 1,
    pageSize: 10,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [privateVisibilityModalVisible, setPrivateVisibilityModalVisible] =
    useState<boolean>(false);
  const [publicVisibilityModalVisible, setPublicVisibilityModalVisible] =
    useState<boolean>(false);
  const [actionItemIdSelected, setActionItemIdSelected] = useState<string>("");
  const navigate = useNavigate();

  async function getAllFormTemplates(
    paginationPage: number = paginationModel.page
  ) {
    const _getAllFormTemplates: any = await FormTemplate.getAllFormTemplates(
      paginationPage,
      paginationModel.pageSize
    );

    if (_getAllFormTemplates.data.length > 0) {
      let dataMemo: any = [];
      // Flatten data
      await _getAllFormTemplates.data.reduce(
        async (promise: any, item: any, index: number) => {
          await promise;
          dataMemo.push({
            id: item.id,
            type: item.user,
            name: item.attributes.name,
            visibility: item.attributes.visibility,
            createdAt: item.attributes.createdAt,
            updatedAt: item.attributes.updatedAt,
          });
        },
        Promise.resolve()
      );
      setFormTemplates(dataMemo);
    }
    const pagination = _getAllFormTemplates.meta.pagination;
    const totalDocuments = pagination.totalDocuments;

    setRowCountState(totalDocuments);
    setLoading(false);
  }

  useEffect(() => {
    getAllFormTemplates();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 180,
    },
    {
      field: "visibility",
      headerName: "Visibility",
      width: 180,
      renderCell: ({ id }) => {
        const item = formTemplates.find((data: any) => data.id === id);

        return item.visibility === "public"
          ? [
              <Tooltip
                key={id}
                title="This template can be copied by barangay admins"
              >
                <Chip
                  label="Public"
                  sx={{
                    backgroundColor: MAIN,
                    color: "white",
                    cursor: "default",
                  }}
                />
              </Tooltip>,
            ]
          : [
              <Tooltip
                key={id}
                title="This template is hidden to barangay admins and copying is not permitted"
              >
                <Chip label="Private" sx={{ cursor: "default" }} />
              </Tooltip>,
            ];
      },
    },
    {
      field: "updatedAt",
      headerName: "Form Template Updated At",
      width: 200,
      cellClassName: "createdAt",
      renderCell: ({ id }) => {
        const item = formTemplates.find((data: any) => data.id === id);
        const date = new Date(item.createdAt).toLocaleDateString();
        const time = new Date(item.createdAt).toLocaleTimeString();

        return [
          <Typography key={id}>
            {date}, {time}
          </Typography>,
        ];
      },
    },
    {
      field: "createdAt",
      headerName: "Form Template Created At",
      width: 200,
      cellClassName: "createdAt",
      renderCell: ({ id }) => {
        const item = formTemplates.find((data: any) => data.id === id);
        const date = new Date(item.createdAt).toLocaleDateString();
        const time = new Date(item.createdAt).toLocaleTimeString();

        return [
          <Typography key={id}>
            {date}, {time}
          </Typography>,
        ];
      },
    },
    {
      field: "action",
      headerName: "",
      width: 200,
      cellClassName: "action",
      renderCell: ({ id }) => {
        const item = formTemplates.find((data: any) => data.id === id);

        return [
          <LongMenu
            key={id}
            options={[
              {
                name: "Edit Form Template Components",
                action: async () => {
                  navigate(`/dashboard/form-template/overview?id=${id}`);
                },
                menuProps: {
                  disabled: item.visibility === "public",
                },
                disabledTooltipMessage:
                  "Form Template must be set to private before editing",
              },
              {
                name: "Edit Form Template Information",
                action: async () => {
                  navigate(`/dashboard/form-template/information?id=${id}`);
                },
                enabledTooltipMessage: "Edit the form title or description",
              },
              {
                name:
                  item.visibility === "public"
                    ? "Set to private"
                    : "Set to public",
                action: async () => {
                  setActionItemIdSelected(item.id);
                  if (item.visibility === "public") {
                    setPrivateVisibilityModalVisible(true);
                  }

                  if (item.visibility === "private") {
                    setPublicVisibilityModalVisible(true);
                  }
                },
                enabledTooltipMessage:
                  item.visibility === "private"
                    ? "Set the form publicly available to copy"
                    : "Set the form private and wont be available for copying",
              },
            ]}
          />,
        ];
      },
    },
  ];

  const ModalComponents = () => {
    return (
      <>
        <BasicModal
          isLoading={modalLoading}
          title="Set this form to private?"
          description={`Setting this form template to private will hide it from barangays when copying a template`}
          isOpen={privateVisibilityModalVisible}
          handleClose={() => {
            setPrivateVisibilityModalVisible(false);
          }}
          handleConfirm={async () => {
            setPrivateVisibilityModalVisible(false);
            await FormTemplate.setFormTemplateVisibility(
              actionItemIdSelected,
              "private"
            );
            await getAllFormTemplates();
          }}
        />
        <BasicModal
          isLoading={modalLoading}
          title="Set this form to public?"
          description={`Setting this form template to public will make it visible for barangays to be copied`}
          isOpen={publicVisibilityModalVisible}
          handleClose={() => {
            setPublicVisibilityModalVisible(false);
          }}
          handleConfirm={async () => {
            setPublicVisibilityModalVisible(false);
            await FormTemplate.setFormTemplateVisibility(
              actionItemIdSelected,
              "public"
            );
            await getAllFormTemplates();
          }}
        />
      </>
    );
  };

  return (
    <Box>
      {!loading ? (
        <>
          <ModalComponents />
          <Box sx={{ height: 400, width: "100%", padding: "10px" }}>
            <DataGridToolbar 
                addNewItem={{
                    onCreate: (values, isLoading, closeModal) => {
                        isLoading(true)

                        // Create form template

                        // Reload list

                        closeModal()
                        isLoading(false)
                    },
                    createItemFields: [
                        {
                            label: 'Form Name',
                            value: 'formName'
                        },
                        {
                            label: 'Description',
                            value: 'description'
                        }
                    ]
                }}
            ></DataGridToolbar>
            <DataGrid
              rows={formTemplates}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    page: 0,
                    pageSize: 5,
                  },
                },
              }}
              onPaginationModelChange={(model) => {
                setPaginationModel({
                  pageSize: 5,
                  page: model.page,
                });
                getAllFormTemplates(model.page + 1);
              }}
              rowCount={rowCountState}
              filterMode="server"
              paginationMode="server"
              pageSizeOptions={[3, 5]}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "30vw",
          }}
        >
          <CircularProgress sx={{ color: MAIN }} />
        </Box>
      )}
    </Box>
  );
}
